import React from "react";
import { useLoaderData } from "react-router-dom";

const RecentContactDetails = () => {
  const { Email, full_name, message, subject } = useLoaderData();

  return (
    <section className="py-20">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white shadow-lg rounded-lg px-8 py-6 mx-auto">
          <div className="mb-6 lg:flex gap-2">
            <p className=" bg-indigo-100 text-indigo-800 p-1 rounded">
              {" "}
              Recent Contact Message
            </p>
          </div>
          <div className="mb-6 lg:flex gap-2">
            <p className="font-semibold">Name:</p>
            <p className="text-gray-600">{full_name}</p>
          </div>

          <div className="mb-6 lg:flex gap-2">
            <p className="font-semibold">Email:</p>
            <p className="text-gray-600">{Email}</p>
          </div>

          <div className="mb-6 lg:flex gap-2">
            <p className="font-semibold">Subject:</p>
            <p className="text-gray-600">{subject}</p>
          </div>
          <div className=" ">
            <p className="font-semibold mb-2">Message:</p>
            <p className="text-gray-600">{message}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecentContactDetails;
