import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../Context/AuthProvider";
import DynamicTitle from "../../dynamic Titale/dynamicTitale";

const Login = () => {
  const { user, userSingIn, userSingOut } = useContext(AuthContext);
  const [EmailVerifi, SetEmailVerifi] = useState(null);
  const [WrongPassword, setWrongPassword] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data) => {
    const Email = data.Email;
    const password = data.Password;

    userSingIn(Email, password)
      .then((data) => {
        if (data.user.emailVerified === false) {
          SetEmailVerifi(false);
          userSingOut()
            .then(() => {})
            .catch(() => {});
          return;
        }

        const Email = data.user.email;
        fetch(`https://foundingforusserver.vercel.app/user/find/${Email}`)
          .then((res) => res.json())
          .then((data) => {
            if (data.EmailVerifi == false) {
              fetch(
                `https://foundingforusserver.vercel.app/user/updated/emailVerifi/${Email}`
              )
                .then((res) => res.json())
                .then((data) => console.log(data));
            }
          });
        navigate("/");
      })

      .catch((err) => setWrongPassword(true));
  };

  return (
    <section className=" py-20">
      <DynamicTitle title={"Login"}></DynamicTitle>
      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0">
          <div className=" lg:w-1/2 mx-auto border p-10 shadow-md">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 gap-5">
                <div className=" grid grid-cols-1 gap-1">
                  <p className=" text-2xl text-secondary font-semibold">
                    Login Here
                  </p>
                  <p className=" text-sm text-[#666666]">
                    Welcome Back!! Login To Your Account
                  </p>
                </div>

                <div>
                  <div className=" border p-4 rounded bg-[#f9f9f9] ">
                    <input
                      type="email"
                      className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                      placeholder=" Email"
                      {...register("Email", { required: true })}
                      aria-invalid={errors.Email ? "true" : "false"}
                    />
                  </div>
                  {errors.Email?.type === "required" && (
                    <p role="alert" className=" text-red-400">
                      Email is required
                    </p>
                  )}
                </div>

                <div>
                  <div className=" border p-4 rounded bg-[#f9f9f9] ">
                    <input
                      type="password"
                      className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                      placeholder=" Password"
                      {...register("Password", { required: true })}
                      aria-invalid={errors.Password ? "true" : "false"}
                    />
                  </div>
                  {errors.Password?.type === "required" && (
                    <p role="alert" className=" text-red-400">
                      Password is required
                    </p>
                  )}
                </div>

                <div>
                  <input
                    type="submit"
                    value="Login"
                    className=" bg-secondary   w-full p-4 rounded text-white text-lg cursor-pointer"
                  />
                </div>
                {WrongPassword == true && (
                  <div>
                    <p className=" text-red-400 font-semibold">
                      {" "}
                      Your password is wrong
                    </p>
                  </div>
                )}
                <div>
                  <Link
                    to="/Password_Reset"
                    className=" text-secondary font-semibold hover:underline"
                  >
                    Forgot your password?
                  </Link>
                </div>
                <div className=" flex gap-3 items-center  text-[#666666]">
                  <p>Don’t Have an Account? </p>
                  <Link
                    to="/Register"
                    className=" text-secondary hover:underline font-semibold"
                  >
                    Create One
                  </Link>
                </div>

                {EmailVerifi === false && (
                  <div>
                    <p className=" text-red-500 font-semibold">
                      {" "}
                      Verifi Your Email
                    </p>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
