import React from "react";
import DashboardManu from "./DashboardManu/DashboardManu";
import { Outlet } from "react-router-dom";

const Dashboard = () => {
  return (
    <section className=" ">
      <div className=" max-w-[1300px] mx-auto ">
        <div>
          <DashboardManu></DashboardManu>
        </div>

        <div>
          <Outlet></Outlet>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
