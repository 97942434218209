import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Context/AuthProvider";
import { toast, Toaster } from "react-hot-toast";

const PasswordReset = () => {
  const { ForgetPassword } = useContext(AuthContext);
  const [userIncorrect, setuserIncorrect] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const onSubmit = (data) => {
    ForgetPassword(data.ResetEmail)
      .then((data) => {
        reset();
        toast.success("Password Reset Email Send");
      })
      .catch((err) => {
        setuserIncorrect(true);
      });
  };
  return (
    <section className=" py-20">
      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0">
          <div className=" lg:w-1/2 border mx-auto  py-10 px-14 rounded shadow-md">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className=" grid grid-cols-1 gap-5"
            >
              <h2 className=" text-2xl font-bold text-center">
                Lost your password?
              </h2>
              <p className=" text-center text-gray-500">
                Please enter your email address. You will receive a link to
                create a new password via email{" "}
                <Link to="/Login" className=" text-secondary underline">
                  Back To Login
                </Link>
              </p>
              <div>
                <input
                  className=" w-full border p-4 rounded outline-none "
                  placeholder=" Type Email"
                  {...register("ResetEmail", { required: true })}
                  aria-invalid={errors.ResetEmail ? "true" : "false"}
                />
                {errors.ResetEmail?.type === "required" && (
                  <p className=" text-red-400" role="alert">
                    Email is required
                  </p>
                )}
              </div>
              {userIncorrect == true && (
                <p className=" text-red-400">
                  There is no user registered with that email address
                </p>
              )}

              <div>
                <input
                  className=" w-full cursor-pointer hover:bg-primary  p-4 rounded bg-secondary font-semibold text-white text-xl"
                  type="submit"
                  value=" Reset Password"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <Toaster />
    </section>
  );
};

export default PasswordReset;
