import React from "react";
import { MdLocationOn, MdEmail } from "react-icons/md";
import { TbPhoneCall } from "react-icons/tb";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import DynamicTitle from "../../dynamic Titale/dynamicTitale";

const Contact = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const onSubmit = (data) => {
    fetch("https://foundingforusserver.vercel.app/recent/contact/message", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged) {
          toast.success("Message");
          reset();
        }
      });
  };
  return (
    <section className=" py-20">
      <DynamicTitle title={"Contact"}></DynamicTitle>
      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0">
          <div className=" grid grid-cols-1 lg:grid-cols-3 gap-10">
            <div className=" bg-secondary p-5 rounded flex gap-8">
              <div className=" w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center">
                <MdLocationOn className=" text-secondary text-4xl"></MdLocationOn>
              </div>
              <div>
                <p className=" text-2xl font-semibold text-white">
                  Our Location
                </p>
                <p className=" text-white mt-1">
                  510 South Pacific Mineola TX 75773
                </p>
              </div>
            </div>
            <div className=" bg-secondary p-5 rounded flex gap-8">
              <div className=" w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center">
                <MdEmail className=" text-secondary text-4xl"></MdEmail>
              </div>
              <div>
                <p className=" text-2xl font-semibold text-white">Email Us</p>
                <p className=" text-white mt-1">info@FundingForUs.net</p>
              </div>
            </div>
            <div className=" bg-secondary p-5 rounded flex gap-8">
              <div className=" w-[60px] h-[60px] bg-white rounded-full flex items-center justify-center">
                <TbPhoneCall className=" text-secondary text-4xl"></TbPhoneCall>
              </div>
              <div>
                <p className=" text-2xl font-semibold text-white">Phone</p>
                <p className=" text-white mt-1">+1 248-539-5404</p>
              </div>
            </div>
          </div>

          <div className=" mt-10 ">
            <div className=" grid grid-cols-1 gap-3 py-10 text-center text-secondary">
              <p className=" text-secondary font-bold ">Send Us A Message</p>
              <p className=" text-4xl text-black font-bold">
                Do You have Any Questions?
              </p>
            </div>
            <form
              className=" grid grid-cols-1 gap-5    p-10  lg:w-1/2 mx-auto"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className=" grid lg:grid-cols-2 gap-5">
                <div>
                  <label>Full Name</label>
                  <input
                    {...register("full_name", { required: true })}
                    aria-invalid={errors.full_name ? "true" : "false"}
                    className=" border border-secondary mt-2 outline-none w-full py-4 px-3  rounded"
                    placeholder=" what is your full name?"
                  />{" "}
                  {errors.full_name?.type === "required" && (
                    <p role="alert" className=" text-red-500">
                      Full Name is required
                    </p>
                  )}
                </div>
                <div>
                  <label>Email</label>
                  <input
                    {...register("Email", { required: true })}
                    aria-invalid={errors.Email ? "true" : "false"}
                    className=" border border-secondary mt-2 outline-none w-full py-4 px-3  rounded"
                    placeholder=" abc@gmail.com"
                  />{" "}
                  {errors.Email?.type === "required" && (
                    <p role="alert" className=" text-red-500">
                      Email is required
                    </p>
                  )}
                </div>
              </div>
              <div>
                <label>Subject</label>
                <input
                  {...register("subject", { required: true })}
                  aria-invalid={errors.subject ? "true" : "false"}
                  className=" border border-secondary mt-2 outline-none w-full py-4 px-3  rounded"
                  placeholder=" Subject"
                />
                {errors.subject?.type === "required" && (
                  <p role="alert" className=" text-red-500">
                    Subject is required
                  </p>
                )}
              </div>
              <div>
                <label>Message</label>
                <textarea
                  {...register("message", { required: true })}
                  aria-invalid={errors.message ? "true" : "false"}
                  className="w-full h-32 border border-secondary rounded py-4 px-3 outline-none mt-2"
                  placeholder="message..."
                ></textarea>
                {errors.message?.type === "required" && (
                  <p role="alert" className=" text-red-500">
                    Message is required
                  </p>
                )}
              </div>

              <input
                type="submit"
                value="Send Message"
                className=" bg-secondary w-full py-3 hover:bg-primary cursor-pointer text-white rounded "
              />
            </form>
          </div>
        </div>
      </div>
      <Toaster />
    </section>
  );
};

export default Contact;
