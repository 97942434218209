import React from "react";
import Testimonials from "../Home/Testimonials";
import aboutImg from "../../Assat/about.png";
import { BsCheckAll } from "react-icons/bs";
import DynamicTitle from "../../dynamic Titale/dynamicTitale";

const About = () => {
  return (
    <section className="">
      <DynamicTitle title={"About"}></DynamicTitle>

      <Testimonials></Testimonials>
      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0">
          <div className=" py-20">
            <div className=" grid grid-cols-1 gap-5 text-center text-secondary">
              <h2 className=" text-2xl font-bold text-secondary">
                Our Mission And Values
              </h2>
              <p className=" lg:w-1/2 lg:mx-auto text-black leading-7 ">
                Our mission is to provide accessible and affordable funding
                solutions to businesses of all sizes and industries. We believe
                in empowering entrepreneurs and supporting the growth and
                success of small businesses.
              </p>
            </div>
            <div className=" mt-10 grid grid-cols-1 lg:grid-cols-2 gap-10">
              <div>
                <div className=" grid grid-cols-1 gap-5">
                  <div>
                    <div className=" flex gap-2 items-center">
                      <BsCheckAll className=" text-secondary text-2xl"></BsCheckAll>
                      <h4 className=" font-bold ">Accessibility:</h4>
                    </div>
                    <p>
                      We are committed to making funding options available to
                      businesses, regardless of their size, location, or
                      industry.
                    </p>
                  </div>
                  <div>
                    <div className=" flex gap-2 items-center">
                      <BsCheckAll className=" text-secondary text-2xl"></BsCheckAll>
                      <h4 className=" font-bold ">Affordability:</h4>
                    </div>
                    <p>
                      We strive to offer competitive rates and flexible terms to
                      ensure that funding is affordable for businesses.
                    </p>
                  </div>
                  <div>
                    <div className=" flex gap-2 items-center">
                      <BsCheckAll className=" text-secondary text-2xl"></BsCheckAll>
                      <h4 className=" font-bold ">Empowerment:</h4>
                    </div>
                    <p>
                      We believe in empowering entrepreneurs and supporting the
                      growth and success of small businesses.
                    </p>
                  </div>
                  <div>
                    <div className=" flex gap-2 items-center">
                      <BsCheckAll className=" text-secondary text-2xl"></BsCheckAll>
                      <h4 className=" font-bold ">Trust:</h4>
                    </div>
                    <p>
                      We are dedicated to building trust with our clients by
                      providing transparent, fair, and ethical funding solutions
                    </p>
                  </div>
                  <div>
                    <div className=" flex gap-2 items-center">
                      <BsCheckAll className=" text-secondary text-2xl"></BsCheckAll>
                      <h4 className=" font-bold ">Innovation:</h4>
                    </div>
                    <p>
                      We are committed to continuously improving our products
                      and services and staying ahead of the curve in the world
                      of business funding.
                    </p>
                  </div>
                  <div>
                    <div className=" flex gap-2 items-center">
                      <BsCheckAll className=" text-secondary text-2xl"></BsCheckAll>
                      <h4 className=" font-bold ">Community:</h4>
                    </div>
                    <p>
                      We strive to be a positive and active member of the
                      business community, supporting initiatives and causes that
                      make a positive impact.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <img src={aboutImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
