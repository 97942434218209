import React from "react";
import img from "../../Assat/how it work.png";
import { BsCheckAll } from "react-icons/bs";

const HowITwork = () => {
  return (
    <section className=" py-20">
      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0">
          <div className=" grid grid-cols-1 gap-5 text-center text-secondary">
            <p className="">How It Works</p>
            <p className=" text-4xl text-black font-bold">
              Eligibility criteria
            </p>
          </div>

          <div className=" mt-10 grid grid-cols-1 lg:grid-cols-2 gap-5">
            <div className=" ">
              <img src={img} />
            </div>

            <div>
              <div className=" grid grid-cols-1 gap-6">
                <p className=" ">
                  New Businesses are welcome to apply as we have lenders who
                  funds newly created business.
                </p>

                <div>
                  <div className=" flex gap-2 items-center">
                    <BsCheckAll className=" text-secondary text-2xl"></BsCheckAll>
                    <h4 className=" font-bold">Credit Score:</h4>
                  </div>
                  <p>
                    A good personal and business credit score is usually best,
                    with a minimum score of 600 or higher
                  </p>
                </div>
                <div>
                  <div className=" flex gap-2 items-center">
                    <BsCheckAll className=" text-secondary text-2xl"></BsCheckAll>
                    <h4 className=" font-bold">Collateral:</h4>
                  </div>
                  <p>
                    Some lenders may require collateral, such as property or
                    equipment, to secure the loan. Don’t worry, we can provide
                    you with the collateral
                  </p>
                </div>
                <div>
                  <div className=" flex gap-2 items-center">
                    <BsCheckAll className=" text-secondary text-2xl"></BsCheckAll>
                    <h4 className=" font-bold">Business Plan:</h4>
                  </div>
                  <p>
                    A well-written and comprehensive business plan is typically
                    required to attract investors and crowdfunding supporters.
                    Don’t worry we’ll help you with the business plan
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowITwork;
