import React, { createContext, useEffect, useState } from "react";
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import app from "../Firebase/Firebase.init";

export const AuthContext = createContext();
const auth = getAuth(app);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // user email and password register
  const userRegister = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };
  // user Email verification
  const verifiEmail = () => {
    return sendEmailVerification(auth.currentUser);
  };

  // user signIn with email and password
  const userSingIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  // user singout
  const userSingOut = () => {
    return signOut(auth);
  };

  // reset password
  const ForgetPassword = (email) => {
    return sendPasswordResetEmail(auth, email);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe;
  }, []);

  const authInfo = {
    user,
    userRegister,
    verifiEmail,
    userSingIn,
    userSingOut,
    ForgetPassword,
  };
  return (
    <AuthContext.Provider value={authInfo}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
