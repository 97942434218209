// import React from "react";
// import { useEffect } from "react";

import { useEffect } from "react";

// const App = () => {
//   useEffect(() => {
//     document.title = "Example title";
//   }, []);

//   return <div>App body here</div>;
// };

// export default App;

const DynamicTitle = ({ title }) => {
  useEffect(() => {
    document.title = `${title} - FundingForUS`;
  }, [title]);
};

export default DynamicTitle;
