import React from "react";
import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";
import logo from "../Assat/logo/white logo.png";

const Footer = () => {
  return (
    <footer className="  bg-primary">
      <div className="footer py-20  max-w-[1300px] mx-auto  text-base-content">
        <div>
          <img src={logo} />
          <div className=" mt-5">
            <p className=" lg:w-[500px] text-[#a8a4a4] text-justify leading-6 text-[16px]">
              Our purpose is to ignite the entrepreneurial spirit and empower
              business owners to achieve their dreams. We believe that every
              small business has the potential to change the world and make a
              positive impact on their communities. That's why we're dedicated
              to providing access to funding, resources, and support to help
              entrepreneurs bring their visions to life.
            </p>
          </div>

          <ul className="grid grid-flow-col gap-8 text-4xl text-secondary mt-5 ">
            <li className="cursor-pointer">
              <a href="https://www.facebook.com/" target="_blank">
                <FaFacebook></FaFacebook>
              </a>
            </li>
            <li className="cursor-pointer">
              <a href="https://twitter.com/?lang=en" target="_blank">
                <FaTwitter></FaTwitter>
              </a>
            </li>
            <li className="cursor-pointer">
              <a href="https://www.linkedin.com/" target="_blank">
                <FaLinkedin></FaLinkedin>{" "}
              </a>
            </li>
          </ul>
        </div>

        <div>
          <span className=" text-xl font-bold text-white"> Quick Links </span>
          <Link
            to="/"
            className="link link-hover text-[#a8a4a4] text-md mt-5 hover:text-secondary hover:font-bold"
          >
            Home
          </Link>
          <Link
            to="/about"
            className="link link-hover text-[#a8a4a4] text-md  hover:text-secondary hover:font-bold"
          >
            About
          </Link>
          <Link
            to="/contact"
            className="link link-hover text-[#a8a4a4] text-md  hover:text-secondary hover:font-bold"
          >
            Contact
          </Link>
          <Link
            to="/blog"
            className="link link-hover text-[#a8a4a4] text-md  hover:text-secondary hover:font-bold"
          >
            Blog
          </Link>
          <Link
            to="/faq"
            className="link link-hover text-[#a8a4a4] text-md  hover:text-secondary hover:font-bold"
          >
            FAQ
          </Link>
        </div>

        <div>
          <span className=" text-xl font-bold text-white"> Services </span>
          <Link
            to="/loan"
            className="link link-hover text-[#a8a4a4] text-md mt-5 hover:text-secondary hover:font-bold"
          >
            Loan
          </Link>
          <Link className="link link-hover text-[#a8a4a4] text-md  hover:text-secondary hover:font-bold">
            Credit Repair
          </Link>
        </div>
      </div>
      <div className=" py-3 bg-[#030729]">
        <p className=" text-center text-white">
          © 2024 FundingForUS . All Rights Reserved
        </p>
      </div>
    </footer>
  );
};

export default Footer;
