import React from "react";
import Banner from "./Banner";
import OurService from "./OurService";
import HowITwork from "./HowITwork";
import CreditRepair from "./CreditRepair";
import Testimonials from "./Testimonials";
import WeLoveHelp from "./WeLoveHelp";
import LatestArticles from "./LatestArticles";
import DynamicTitle from "../../dynamic Titale/dynamicTitale";

const Home = () => {
  return (
    <>
      <DynamicTitle title={"Home"}></DynamicTitle>
      <Banner></Banner>
      <OurService></OurService>
      <HowITwork></HowITwork>
      <WeLoveHelp></WeLoveHelp>
      <CreditRepair></CreditRepair>
      <Testimonials></Testimonials>
      <LatestArticles></LatestArticles>
    </>
  );
};

export default Home;
