import React from "react";
import { FaSpinner } from "react-icons/fa";

const Spinner = () => {
  return (
    <div className=" flex items-center justify-center mt-5">
      <button className=" flex items-center justify-center rounded-lg bg-secondary px-4 py-2 gap-2 text-white">
        <FaSpinner className=" animate-spin"></FaSpinner>
        <span className=" font-medium"> Loading...</span>
      </button>
    </div>
  );
};

export default Spinner;
