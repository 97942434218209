import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import DynamicTitle from "../../dynamic Titale/dynamicTitale";

const LoanApplication = () => {
  // EntityTypes start
  const [EntityTypes, setEntityType] = useState([]);
  useEffect(() => {}, [
    fetch("EntityType.json")
      .then((res) => res.json())
      .then((data) => setEntityType(data)),
  ]);
  // EntityTypes End

  // Industrys data start
  const [Industrys, setIndustrys] = useState([]);

  useEffect(() => {
    fetch("Industry.json")
      .then((res) => res.json())
      .then((data) => setIndustrys(data));
  }, []);
  // Industrys data end

  // all countries start
  const [countries, setcountries] = useState([]);

  useEffect(() => {
    fetch("https://restcountries.com/v2/all")
      .then((res) => res.json())
      .then((data) => setcountries(data));
  }, []);
  // all countries end

  // ====================== Date Of Birth =====================\\
  const days = Array.from({ length: 31 }, (_, index) => index + 1);
  const months = Array.from({ length: 12 }, (_, index) => {
    const date = new Date(0, index);
    return date.toLocaleString("default", { month: "long" });
  });
  const [years, setYears] = useState([]);
  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const yearList = Array.from(
      { length: currentYear - 1949 },
      (_, index) => 1950 + index
    );
    setYears(yearList);
  }, []);
  // get year end

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const onSubmit = (data) => {
    fetch("https://foundingforusserver.vercel.app/loan/recent_application", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged) {
          reset();
          toast.success("Thanks for your Application ");
        }
      });
  };

  return (
    <section className=" py-20">
      <DynamicTitle title={"Loan Application"}></DynamicTitle>
      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0">
          <div className=" lg:w-1/2 p-10 shadow-md  lg:mx-auto grid grid-cols-1 gap-10 border">
            <div className=" grid grid-cols-1 gap-1">
              <p className=" text-2xl text-secondary font-semibold">
                Loan Application
              </p>
              <p className=" text-sm text-[#666666]">Welcome To FundingForUS</p>
            </div>
            {/* form start here */}
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className=" grid grid-cols-1 gap-5">
                  <div>
                    <p className=" text-lg   "> Parsonal Information</p>
                  </div>
                  {/* first name */}
                  <div>
                    <div className=" border p-4 rounded bg-[#f9f9f9] ">
                      <input
                        className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                        placeholder=" First Name"
                        {...register("firstName", { required: true })}
                        aria-invalid={errors.firstName ? "true" : "false"}
                      />
                    </div>
                    {errors.firstName?.type === "required" && (
                      <p role="alert" className=" text-red-400">
                        First name is required
                      </p>
                    )}
                  </div>
                  {/* last name */}
                  <div>
                    <div className=" border p-4 rounded bg-[#f9f9f9] ">
                      <input
                        className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                        placeholder=" Last Name"
                        {...register("Last_Name", { required: true })}
                        aria-invalid={errors.Last_Name ? "true" : "false"}
                      />
                    </div>
                    {errors.Last_Name?.type === "required" && (
                      <p role="alert" className=" text-red-400">
                        Last name is required
                      </p>
                    )}
                  </div>
                  {/* email */}
                  <div>
                    <div className=" border p-4 rounded bg-[#f9f9f9] ">
                      <input
                        type="email"
                        className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                        placeholder=" Email"
                        {...register("email", { required: true })}
                        aria-invalid={errors.email ? "true" : "false"}
                      />
                    </div>
                    {errors.email?.type === "required" && (
                      <p role="alert" className=" text-red-400">
                        Email is required
                      </p>
                    )}
                  </div>
                  {/* phone */}
                  <div>
                    <div className=" border p-4 rounded bg-[#f9f9f9] ">
                      <input
                        type="number"
                        className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                        placeholder=" Number"
                        {...register("number", { required: true })}
                        aria-invalid={errors.number ? "true" : "false"}
                      />
                    </div>
                    {errors.number?.type === "required" && (
                      <p role="alert" className=" text-red-400">
                        Phone is required
                      </p>
                    )}
                  </div>
                  {/* Home Address*/}
                  <div>
                    <p className=" text-lg font-semibold"> Home Address</p>
                  </div>
                  {/* Address */}
                  <div>
                    <div className=" border p-4 rounded bg-[#f9f9f9] ">
                      <input
                        type="text"
                        className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                        placeholder=" Address"
                        {...register("Address", { required: true })}
                        aria-invalid={errors.Address ? "true" : "false"}
                      />
                    </div>
                    {errors.Address?.type === "required" && (
                      <p role="alert" className=" text-red-400">
                        Address is required
                      </p>
                    )}
                  </div>
                  {/* city */}
                  <div>
                    <div className=" border p-4 rounded bg-[#f9f9f9] ">
                      <input
                        type="text"
                        className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                        placeholder=" City"
                        {...register("City", { required: true })}
                        aria-invalid={errors.City ? "true" : "false"}
                      />
                    </div>
                    {errors.City?.type === "required" && (
                      <p role="alert" className=" text-red-400">
                        City is required
                      </p>
                    )}
                  </div>
                  {/* State */}
                  <div>
                    <div className=" border p-4 rounded bg-[#f9f9f9] ">
                      <input
                        type="text"
                        className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                        placeholder=" State"
                        {...register("State", { required: true })}
                        aria-invalid={errors.State ? "true" : "false"}
                      />
                    </div>
                    {errors.State?.type === "required" && (
                      <p role="alert" className=" text-red-400">
                        State is required
                      </p>
                    )}
                  </div>
                  {/* Postal Code */}
                  <div>
                    <div className=" border p-4 rounded bg-[#f9f9f9] ">
                      <input
                        type="number"
                        className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                        placeholder=" Postal Code"
                        {...register("Postal_Code", { required: true })}
                        aria-invalid={errors.Postal_Code ? "true" : "false"}
                      />
                    </div>
                    {errors.Postal_Code?.type === "required" && (
                      <p role="alert" className=" text-red-400">
                        Postal Code is required
                      </p>
                    )}
                  </div>
                  {/* Country */}

                  <div>
                    <div className="  mt-2 ">
                      <select
                        className="border p-4 bg-[#f9f9f9] w-full text-[#666666]  rounded outline-none"
                        {...register("Country", { required: true })}
                        aria-invalid={errors.Country ? "true" : "false"}
                      >
                        <option value="" className=" ">
                          Countrie
                        </option>
                        {countries.map((country, i) => (
                          <option value={country.name} key={i}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errors.Country?.type === "required" && (
                      <p role="alert" className=" text-red-400">
                        Country is required
                      </p>
                    )}
                  </div>
                  {/* Date Of Birth */}
                  <div>
                    <p className=" text-lg font-semibold"> Date Of Birth</p>
                  </div>
                  {/* day */}
                  <div>
                    <div className="  mt-2 ">
                      <select
                        className="border p-4 bg-[#f9f9f9] w-full text-[#666666]  rounded outline-none"
                        {...register("Day", { required: true })}
                        aria-invalid={errors.Day ? "true" : "false"}
                      >
                        <option value="" className=" ">
                          Day
                        </option>
                        {days.map((day, i) => (
                          <option key={i} value={day}>
                            {day}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errors.Day?.type === "required" && (
                      <p role="alert" className=" text-red-400">
                        Day is required
                      </p>
                    )}
                  </div>
                  {/* Month */}
                  <div>
                    <div className="  mt-2 ">
                      <select
                        className="border p-4 bg-[#f9f9f9] w-full text-[#666666]  rounded outline-none"
                        {...register("Month", { required: true })}
                        aria-invalid={errors.Month ? "true" : "false"}
                      >
                        <option value="" className=" ">
                          Month
                        </option>
                        {months.map((month, i) => (
                          <option key={i} value={month}>
                            {month}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errors.Month?.type === "required" && (
                      <p role="alert" className=" text-red-400">
                        Month is required
                      </p>
                    )}
                  </div>
                  {/* Year */}
                  <div>
                    <div className="  mt-2 ">
                      <select
                        className="border p-4 bg-[#f9f9f9] w-full text-[#666666]  rounded outline-none"
                        {...register("Year", { required: true })}
                        aria-invalid={errors.Year ? "true" : "false"}
                      >
                        <option value="" className=" ">
                          Year
                        </option>
                        {years.map((year, i) => (
                          <option key={i} value={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errors.Year?.type === "required" && (
                      <p role="alert" className=" text-red-400">
                        Year is required
                      </p>
                    )}
                  </div>
                  {/* Business Information (Optional)
                   */}
                  <div>
                    <p className=" text-lg font-semibold">
                      Business Information (Optional)
                    </p>
                  </div>
                  {/* Business Name */}
                  <div>
                    <div className=" border p-4 rounded bg-[#f9f9f9] ">
                      <input
                        type="text"
                        className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                        placeholder=" Business Name"
                        {...register("Business_Name", { required: true })}
                      />
                    </div>
                  </div>
                  {/* Business start date */}
                  <div>
                    <label className=" "> Business Start Date</label>

                    <div className=" border p-4 rounded bg-[#f9f9f9] mt-2 ">
                      <input
                        type="date"
                        className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                        placeholder=" Business Name"
                        {...register("Business_start_date", { required: true })}
                      />
                    </div>
                  </div>
                  {/* Entity Type */}
                  <div>
                    <div className="  mt-2 ">
                      <select
                        className="border p-4 bg-[#f9f9f9] w-full text-[#666666]  rounded outline-none"
                        {...register("Entity_Type", { required: true })}
                        aria-invalid={errors.Entity_Type ? "true" : "false"}
                      >
                        <option value="" className=" ">
                          Entity Type
                        </option>
                        {EntityTypes.map((EntityType, i) => (
                          <option key={i} value={EntityType}>
                            {EntityType}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* industry */}
                  <div>
                    <div className="  mt-2 ">
                      <select
                        className="border p-4 bg-[#f9f9f9] w-full text-[#666666]  rounded outline-none"
                        {...register("Industry", { required: true })}
                        aria-invalid={errors.Industry ? "true" : "false"}
                      >
                        <option value="">Industry</option>
                        {Industrys.map((Industry, i) => (
                          <option key={i} value={Industry}>
                            {Industry}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {/* Average Monthly Revenue */}
                  <div>
                    <div className="  mt-2 ">
                      <select
                        className="border p-4 bg-[#f9f9f9] w-full text-[#666666]  rounded outline-none"
                        {...register("Average_Monthly_Revenue", {
                          required: true,
                        })}
                        aria-invalid={
                          errors.Average_Monthly_Revenue ? "true" : "false"
                        }
                      >
                        <option value="">Average Monthly Revenue</option>
                        <option value="0-9999">$0-$9,999</option>
                        <option value="10000+">$10,000+</option>
                      </select>
                    </div>
                  </div>
                  {/* loan about */}
                  <div>
                    <p className=" text-justify text-[#666666]">
                      You understand by clicking the I AGREE box, you are
                      providing 'written instructions' to 7 Figures Funding
                      under the Fair Credit Reporting Act, authorizing 7 Figures
                      Funding to obtain information from your personal credit
                      profile or other information from Experian. You authorize
                      7 Figures Funding to obtain such information solely to
                      conduct a pre-qualification for credit. Our proprietary
                      software uses a 'soft' credit pull to match your credit
                      history with loan criteria, providing a reliable
                      pre-qualification without impacting your credit.
                      Additionally, pre-qualifying consumers may receive special
                      loan offers tailored just for them!
                    </p>
                  </div>
                  {/* trams and condition */}

                  <div>
                    <div className=" border p-4 rounded bg-[#f9f9f9] mt-2 ">
                      <div className=" flex gap-3">
                        <input
                          type="checkbox"
                          {...register("Terms_and_Conditions", {
                            required: true,
                          })}
                          aria-invalid={
                            errors.Terms_and_Conditions ? "true" : "false"
                          }
                        />

                        <a href="" className="underline text-secondary">
                          Accepts Terms and Conditions
                        </a>
                      </div>
                    </div>{" "}
                    {errors.Terms_and_Conditions?.type === "required" && (
                      <p role="alert" className=" text-red-400">
                        Accepts Trams And Condition
                      </p>
                    )}
                  </div>
                  {/* Electronic Disclosures */}
                  <div>
                    <div className=" border p-4 rounded bg-[#f9f9f9] mt-2 ">
                      <div className=" flex gap-3">
                        <input
                          type="checkbox"
                          {...register("Electronic_Disclosures", {
                            required: true,
                          })}
                          aria-invalid={
                            errors.Electronic_Disclosures ? "true" : "false"
                          }
                        />
                        <div className=" flex gap-2">
                          <p>
                            I certify that I have read and agreed to the Consent
                            to Electronic Disclosures provided{" "}
                            <a className=" text-secondary underline" href="/">
                              {" "}
                              Here
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>{" "}
                    {errors.Electronic_Disclosures?.type === "required" && (
                      <p role="alert" className=" text-red-400">
                        Accepts Electronic Disclosures
                      </p>
                    )}
                  </div>

                  <div>
                    <input
                      type="submit"
                      value="Apply Now"
                      className=" bg-secondary w-full p-4 rounded text-white text-lg cursor-pointer"
                    />
                  </div>
                </div>
              </form>
            </div>
            {/* form end here */}
          </div>
        </div>
      </div>
      <Toaster />
    </section>
  );
};

export default LoanApplication;
