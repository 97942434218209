import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../../Spinner/Spinner";

const Dashboard_loan = () => {
  const [spinner, setSpinner] = useState(true);

  const { data: AllLoans = [], refetch } = useQuery({
    queryKey: ["all_loan_application"],
    queryFn: async () => {
      const res = await fetch(
        "https://foundingforusserver.vercel.app/Loan/Application"
      );
      const data = await res.json();
      setSpinner(false);
      return data;
    },
  });

  // deleteLoan
  const deleteLoan = (id) => {
    fetch(
      `https://foundingforusserver.vercel.app/Loan/Application/delete/${id}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged) {
          refetch();
        }
      });
  };
  return (
    <section className=" py-10">
      <div className=" grid grid-cols-1 gap-5">
        <div className=" lg:flex grid grid-cols-1 gap-2 justify-between items-center">
          <p className=" font-semibold capitalize">
            Dashboard /
            <span className=" px-2 py-1 ml-1 rounded-full bg-indigo-100 text-indigo-800">
              Loan Application
            </span>
          </p>
          <Link
            to="/Dashboard/loan/recent_application"
            className=" px-5 w-max inline-flex text-xs leading-8 font-semibold rounded-full bg-secondary text-white"
          >
            Recent Loan Application
          </Link>
        </div>

        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                {spinner == true && <Spinner></Spinner>}

                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        SN
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        FIRST NAME
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Last Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Phone
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Country
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Details
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {AllLoans.map((AllLoan, i) => (
                      <tr key={AllLoan._id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-900">
                          {i + 1}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-900">
                          {AllLoan.firstName}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                          {AllLoan.Last_Name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {AllLoan.email}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            {AllLoan.number}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {AllLoan.Country}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                          <Link to={`/Dashboard/loan/details/${AllLoan._id}`}>
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-cyan-100 text-cyan-800">
                              Details
                            </span>
                          </Link>
                        </td>
                        <td
                          onClick={() => {
                            deleteLoan(AllLoan._id);
                          }}
                          className="px-6 py-4 whitespace-nowrap cursor-pointer"
                        >
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            Delate
                          </span>
                        </td>
                      </tr>
                    ))}
                    {/* Add more rows as needed */}
                  </tbody>
                </table>

                {AllLoans.length < 1 && (
                  <p className="px-6 py-4 whitespace-nowrap text-sm bg-red-100 text-red-900">
                    No Application available{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard_loan;
