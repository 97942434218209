import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { AuthContext } from "../../Context/AuthProvider";
import { toast, Toaster } from "react-hot-toast";
import DynamicTitle from "../../dynamic Titale/dynamicTitale";

const Register = () => {
  const { user, userRegister, verifiEmail } = useContext(AuthContext);
  const [EmailUsed, setEmailUsed] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const onSubmit = (data) => {
    const email = data.Email;
    const password = data.Password;
    const Name = data.Full_name;

    userRegister(email, password)
      .then((user) => {
        if (user.user.uid) {
          verifiEmail()
            .then((res) => {})
            .catch((err) => {});
          toast.success("Check You Email and Verifi Email");
          const userInfo = {
            Name,
            email,
            Role: "user",
            EmailVerifi: false,
          };
          fetch("https://foundingforusserver.vercel.app/user_info", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(userInfo),
          })
            .then((res) => res.json())
            .then((data) => console.log(data));
          reset();
        }
      })
      .catch((err) => {
        setEmailUsed(true);
      });
  };
  return (
    <section className=" py-20">
      <DynamicTitle title={"Register"}></DynamicTitle>
      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0">
          <div className=" lg:w-1/2 mx-auto border p-10 shadow-md">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-1 gap-5">
                <div className=" grid grid-cols-1 gap-1">
                  <p className=" text-2xl text-secondary font-semibold">
                    Register New Account
                  </p>
                  <p className=" text-sm text-[#666666]">
                    Welcome!! Create A New Account
                  </p>
                </div>

                <div>
                  <div className=" border p-4 rounded bg-[#f9f9f9] ">
                    <input
                      className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                      placeholder=" Full Name"
                      {...register("Full_name", { required: true })}
                      aria-invalid={errors.Full_name ? "true" : "false"}
                    />
                  </div>
                  {errors.Full_name?.type === "required" && (
                    <p role="alert" className=" text-red-400">
                      Full name is required
                    </p>
                  )}
                </div>

                <div>
                  <div className=" border p-4 rounded bg-[#f9f9f9] ">
                    <input
                      type="email"
                      className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                      placeholder=" Email"
                      {...register("Email", { required: true })}
                      aria-invalid={errors.Email ? "true" : "false"}
                    />
                  </div>
                  {errors.Email?.type === "required" && (
                    <p role="alert" className=" text-red-400">
                      Email is required
                    </p>
                  )}
                </div>

                <div>
                  <div className=" border p-4 rounded bg-[#f9f9f9] ">
                    <input
                      type="password"
                      className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                      placeholder=" Password"
                      {...register("Password", { required: true })}
                      aria-invalid={errors.Password ? "true" : "false"}
                    />
                  </div>
                  {errors.Password?.type === "required" && (
                    <p role="alert" className=" text-red-400">
                      Password is required
                    </p>
                  )}
                </div>
                <div>
                  <input
                    type="submit"
                    value="Register"
                    className=" bg-secondary  w-full p-4 rounded text-white text-lg cursor-pointer"
                  />
                </div>
                {EmailUsed == true && (
                  <p className=" text-red-400 font-semibold capitalize">
                    Already Use This Email
                  </p>
                )}
                <div className=" flex gap-3 items-center text-[#666666]">
                  <p>Have an Account? </p>
                  <Link
                    to="/Login"
                    className=" text-secondary hover:underline font-semibold"
                  >
                    Sign In
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Toaster />
    </section>
  );
};

export default Register;
