import React from "react";
import img from "../../Assat/we-love.png";
import { Link } from "react-router-dom";

const WeLoveHelp = () => {
  return (
    <section className=" py-20 bg-[#eaf5f5]">
      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0">
          <div className=" grid grid-cols-1 lg:grid-cols-2 gap-10">
            <div>
              <h2 className=" text-2xl font-bold mb-5">
                We Love To Help Small Business
              </h2>
              <p className=" leading-7 ">
                As entrepreneurs, we understand the challenges and uncertainties
                that come with starting and growing a business. That's why we're
                here to help. Our mission is to empower and inspire small
                business owners by providing them with the funding they need to
                bring their dreams to life. With a wide range of funding options
                and a team of experts on hand to guide you every step of the
                way, we make it easy for you and your business to access the
                capital you need to grow. Whether you're starting from scratch,
                expanding your operations, simply need a cash infusion, or cash
                for personal use, we have a funding solution that's right for
                you. So, what are you waiting for? Let's get started and turn
                your dreams into reality.
              </p>
              <div className="mt-10">
                <Link
                  to="/loan_application"
                  className="  bg-secondary  w-max mx-auto lg:ml-0 px-8 py-5 rounded text-white"
                >
                  {" "}
                  Apply Now
                </Link>
              </div>
            </div>
            <div>
              <img src={img} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeLoveHelp;
