import React from "react";
import TopHeader from "./TopHeader";
import BottomHeader from "./BottomHeader";

const Header = () => {
  return (
    <header>
      <TopHeader></TopHeader>
      <BottomHeader></BottomHeader>
    </header>
  );
};

export default Header;
