import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import Spinner from "../../../Spinner/Spinner";

const ManageBlog = () => {
  const [spinner, setSpinner] = useState(true);

  const { data: allBlogs = [], refetch } = useQuery({
    queryKey: ["BlogItem"],
    queryFn: async () => {
      const res = await fetch(
        "https://foundingforusserver.vercel.app/Blog/item"
      );
      const data = await res.json();
      setSpinner(false);
      return data;
    },
  });
  const blogDelete = (id) => {
    fetch(`https://foundingforusserver.vercel.app/Blog/item/delete/${id}`, {
      method: "DELETE",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged) {
          refetch();
        }
      });
  };
  return (
    <section className=" py-20">
      <div className=" max-w-[1300px] mx-auto">
        <div>
          <h2 className=" mb-5 text-gray-500 text-lg font-semibold">
            {" "}
            Manage Blog
          </h2>
          {spinner == true && <Spinner></Spinner>}

          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  SN
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Blog Titale
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Publish Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200">
              {allBlogs.map((allBlog, i) => (
                <tr key={allBlog._id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {i + 1}
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {allBlog.Blog_Titale}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      {allBlog.Blog_Date}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex gap-2">
                    <button
                      disabled
                      className=" btn btn-xs bg-green-300 text-green-800 border-none"
                    >
                      Edit
                    </button>
                    <span
                      onClick={() => blogDelete(allBlog._id)}
                      className=" btn btn-xs bg-red-200 text-red-800 border-none"
                    >
                      Delete
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {allBlogs.length < 1 && (
            <p className="px-6 py-4 whitespace-nowrap rounded text-sm text-red-900 bg-red-100">
              No Blog available{" "}
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default ManageBlog;
