import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../../Spinner/Spinner";

const Dashboard_User_admin = () => {
  const [spinner, setSpinner] = useState(true);

  const { data: allAdmins = [], refetch } = useQuery({
    queryKey: ["creditRepairApplication"],
    queryFn: async () => {
      const res = await fetch(
        "https://foundingforusserver.vercel.app/UserInfo/Admin"
      );
      const data = res.json();
      setSpinner(false);
      return data;
    },
  });

  return (
    <section className=" py-10">
      <div className=" grid grid-cols-1 gap-5">
        <div className=" flex justify-between items-center">
          <p className=" font-semibold capitalize">Dashboard / user / Admin </p>
          <Link
            to="/Dashboard/user"
            className=" px-5 inline-flex text-xs leading-8 font-semibold rounded-full bg-secondary text-white"
          >
            View All User
          </Link>
        </div>

        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                {spinner == true && <Spinner></Spinner>}
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Role
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {allAdmins.map((allAdmin) => (
                      <tr key={allAdmin._id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {allAdmin.Name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {allAdmin.email}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            Active
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Admin
                        </td>
                      </tr>
                    ))}

                    {/* Add more rows as needed */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard_User_admin;
