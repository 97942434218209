import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../../Spinner/Spinner";

const Dashboard_loan_recent_application = () => {
  const [spinner, setSpinner] = useState(true);

  // recent loan data load start
  const { data: RecentLoans = [], refetch } = useQuery({
    queryKey: ["loan_recent_application"],
    queryFn: async () => {
      const res = await fetch(
        "https://foundingforusserver.vercel.app/loan/recent/application"
      );
      const data = await res.json();
      setSpinner(false);
      return data;
    },
  });
  // recent loan data load start

  const MarkRead = (id) => {
    fetch(`https://foundingforusserver.vercel.app/loan/recent/mark_read/${id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data._id) {
          const loan_application = {
            Address: data.Address,
            Average_Monthly_Revenue: data.Average_Monthly_Revenue,
            Business_Name: data.Business_Name,
            Business_start_date: data.Business_start_date,
            City: data.City,
            Country: data.Country,
            Day: data.Day,
            Electronic_Disclosures: data.Electronic_Disclosures,
            Entity_Type: data.Entity_Type,
            Industry: data.Industry,
            Last_Name: data.Last_Name,
            Month: data.Month,
            Postal_Code: data.Postal_Code,
            State: data.State,
            Terms_and_Conditions: data.Terms_and_Conditions,
            Year: data.Year,
            email: data.email,
            firstName: data.firstName,
            number: data.number,
            order_id: data._id,
          };
          fetch("https://foundingforusserver.vercel.app/loan/application", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(loan_application),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.acknowledged) {
                fetch(
                  `https://foundingforusserver.vercel.app/loan/recent/delete/${id}`,
                  {
                    method: "DELETE",
                  }
                )
                  .then((res) => res.json())
                  .then((data) => {
                    if (data.acknowledged) {
                      refetch();
                    }
                  });
              }
            });
        }
      });
  };
  return (
    <section className=" py-10">
      <div className=" grid grid-cols-1 gap-5">
        <div className=" grid grid-cols-1 gap-2 lg:flex justify-between items-center">
          <p className=" font-semibold capitalize">
            Dashboard /
            <span className=" px-2 py-1 ml-1 rounded-full bg-indigo-50 text-secondary">
              Recent Loan Application
            </span>
          </p>
          <Link
            to="/Dashboard/loan_application"
            className=" px-5 w-max inline-flex text-xs leading-8 font-semibold rounded-full bg-secondary text-white"
          >
            All Loan Application
          </Link>
        </div>

        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                {spinner == true && <Spinner></Spinner>}

                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        SN
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        First Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Last Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Phone
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Country
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Details
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200">
                    {RecentLoans.map((RecentLoan, i) => (
                      <tr key={RecentLoan._id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {i + 1}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {RecentLoan.firstName}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {RecentLoan.Last_Name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            {RecentLoan.email}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {RecentLoan.number}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-indigo-200 text-indigo-800">
                            {RecentLoan.Country}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                          <Link
                            to={`/Dashboard/loan/recent/details/${RecentLoan._id}`}
                          >
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-cyan-100 text-cyan-800">
                              Details
                            </span>
                          </Link>
                        </td>
                        <td
                          className="px-6 py-4 whitespace-nowrap cursor-pointer"
                          onClick={() => MarkRead(RecentLoan._id)}
                        >
                          <span className="hover:bg-sky-200 hover:text-sky-900 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-emerald-200 text-emerald-900">
                            Mark Read
                          </span>
                        </td>
                      </tr>
                    ))}

                    {/* Add more rows as needed */}
                  </tbody>
                </table>
                {RecentLoans.length < 1 && (
                  <p className="px-6 py-4 text-red-900 whitespace-nowrap text-sm bg-red-100">
                    No Recent Application available{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard_loan_recent_application;
