import React, { useEffect, useState } from "react";

import { FaCalendarAlt } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
import Spinner from "../../Spinner/Spinner";
import { Link } from "react-router-dom";

const LatestArticles = () => {
  const [spinner, setSpinner] = useState(true);
  const [LatsBlogs, SetLatsBlogs] = useState([]);
  useEffect(() => {
    fetch("https://foundingforusserver.vercel.app/lates_blog")
      .then((res) => res.json())
      .then((data) => {
        SetLatsBlogs(data);
        setSpinner(false);
      });
  }, []);

  return (
    <section className=" py-20 bg-[#f5f3f3]">
      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0 grid grid-cols-1 gap-9">
          <div className=" grid grid-cols-1 gap-5 text-center text-secondary">
            <p className="">Latest Insights From FundingForUS</p>
            <p className=" text-4xl text-black font-bold">
              Our Latest Articles
            </p>
          </div>

          <div>{spinner == true && <Spinner></Spinner>}</div>

          <div className=" grid grid-cols-1 lg:grid-cols-3 gap-10">
            {LatsBlogs.map((LatsBlog) => (
              <div key={LatsBlog._id} className=" bg-white ">
                <div>
                  <img src={LatsBlog.Img} className="  " />
                </div>
                <div className=" p-5 grid grid-cols-1 gap-4">
                  <div className=" text-black flex items-center gap-4">
                    <FaCalendarAlt></FaCalendarAlt>
                    <p>{LatsBlog.Blog_Date}</p>
                  </div>
                  <h2 className="text-black text-2xl font-bold">
                    {LatsBlog.Blog_Titale}
                  </h2>
                  <div className=" text-secondary flex gap-3 items-center font-semibold text-[18px]">
                    <Link
                      to={`/Latest/blog/details/${LatsBlog._id}`}
                      className=" cursor-pointer"
                    >
                      Read More
                    </Link>
                    <BsArrowRight></BsArrowRight>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default LatestArticles;
