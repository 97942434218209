import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../../Spinner/Spinner";

const Dashboard_User = () => {
  const [spinner, setSpinner] = useState(true);

  const { data: allusers = [], refetch } = useQuery({
    queryKey: ["creditRepairApplication"],
    queryFn: async () => {
      const res = await fetch(
        "https://foundingforusserver.vercel.app/UserInfo/user"
      );
      const data = res.json();
      setSpinner(false);
      return data;
    },
  });

  const mackAdmin = (id) => {
    fetch(`https://foundingforusserver.vercel.app/user/mack_admin/${id}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged) {
          console.log(data);
          refetch();
        }
      });
  };
  return (
    <section className=" py-10">
      <div className=" grid grid-cols-1 gap-5">
        <div className=" flex justify-between items-center">
          <p className=" font-semibold capitalize">Dashboard / user </p>
          <Link
            to="/Dashboard/user/admin"
            className=" px-5 inline-flex text-xs leading-8 font-semibold rounded-full bg-secondary text-white"
          >
            View All Admin
          </Link>
        </div>

        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                {spinner == true && <Spinner></Spinner>}

                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Role
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Email Verifi
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200">
                    {allusers.map((user) => (
                      <tr key={user._id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {user.Name}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {user.email}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            Active
                          </span>
                        </td>
                        <td className="px-6 py-4 capitalize whitespace-nowrap text-sm text-gray-500">
                          <span>{user.Role}</span>
                        </td>
                        <td className="px-6 py-4 capitalize whitespace-nowrap text-sm text-gray-500">
                          {user.EmailVerifi == true ? (
                            <span className="text-green-800 bg-green-100  px-1 rounded text-center">
                              Yes
                            </span>
                          ) : (
                            <span className=" text-red-800 bg-red-100 px-1 rounded text-center">
                              No
                            </span>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap flex gap-2">
                          <span
                            onClick={() => mackAdmin(user._id)}
                            className="px-2 cursor-pointer inline-flex text-xs leading-5 font-semibold rounded-full bg-green-200 text-green-900"
                          >
                            Mack Admin
                          </span>
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            Delete
                          </span>
                        </td>
                      </tr>
                    ))}

                    {/* Add more rows as needed */}
                  </tbody>
                </table>
                {allusers.length < 1 && (
                  <p className="px-6 py-4 text-red-900 whitespace-nowrap text-sm bg-red-100">
                    No user available{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard_User;
