import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../../Spinner/Spinner";

const Recent_Contact_Message = () => {
  const [spinner, setSpinner] = useState(true);

  const { data: RecentContactMessages = [], refetch } = useQuery({
    queryKey: ["RecentContactMessage"],
    queryFn: async () => {
      const res = await fetch(
        "https://foundingforusserver.vercel.app/recent/contact/message"
      );
      const data = await res.json();
      setSpinner(false);
      return data;
    },
  });

  const answer = (id) => {
    fetch(
      `https://foundingforusserver.vercel.app/recent/contact/message/answer/${id}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data._id) {
          const ContactMessage = {
            Contact_ID: data._id,
            Email: data.Email,
            full_name: data.full_name,
            message: data.message,
            subject: data.subject,
          };

          fetch("https://foundingforusserver.vercel.app/contact/message", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(ContactMessage),
          })
            .then((res) => res.json())
            .then((data) => {
              if (data.acknowledged) {
                fetch(
                  `https://foundingforusserver.vercel.app/recent/contact/message/delete/${id}`,
                  {
                    method: "DELETE",
                  }
                )
                  .then((res) => res.json())
                  .then((data) => {
                    if (data.acknowledged) {
                      refetch();
                    }
                  });
              }
            });
        }
      });
  };

  return (
    <section className=" py-10">
      <div className=" grid grid-cols-1 gap-5">
        <div className=" grid grid-cols-1 gap-2 lg:flex justify-between items-center">
          <p className=" font-semibold capitalize">
            Dashboard /
            <span className=" px-2 py-1 ml-1 rounded-full bg-indigo-50 text-secondary">
              Recent Contact Message
            </span>
          </p>
          <Link
            to="/Dashboard/Contact_Message"
            className=" px-5 w-max inline-flex text-xs leading-8 font-semibold rounded-full bg-secondary text-white"
          >
            All Contact Message
          </Link>
        </div>

        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                {spinner == true && <Spinner></Spinner>}

                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        SN
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Email
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Subject
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Details
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>

                  <tbody className="bg-white divide-y divide-gray-200">
                    {RecentContactMessages.map((RecentContactMessage, i) => (
                      <tr key={RecentContactMessage._id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {i + 1}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {RecentContactMessage.full_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {RecentContactMessage.Email}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            {RecentContactMessage.subject}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <Link
                            to={`/Dashboard/recent/contact/details/${RecentContactMessage._id}`}
                          >
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full cursor-pointer bg-indigo-100 text-indigo-800">
                              view
                            </span>
                          </Link>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <span
                            onClick={() => answer(RecentContactMessage._id)}
                            className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full cursor-pointer bg-purple-100 text-purple-700"
                          >
                            Answer
                          </span>
                        </td>
                      </tr>
                    ))}

                    {/* Add more rows as needed */}
                  </tbody>
                </table>
                {RecentContactMessages.length < 1 && (
                  <p className="px-6 py-4 whitespace-nowrap text-sm text-red-900 bg-red-100">
                    No Recent Message available{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Recent_Contact_Message;
