import React, { useState } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";

const BlogPost = () => {
  const [blogImg, SetblogImg] = useState(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const imgBBKey = "78d0dabe3bb442f19190774e908a1410";

  const handleImg = async (e) => {
    e.preventDefault();
    const image = e.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    const res = await fetch(
      `https://api.imgbb.com/1/upload?expiration=600&key=${imgBBKey}`,
      { method: "POST", body: formData }
    );
    const data = await res.json();
    SetblogImg(data.data.url);
  };

  const onSubmit = (data) => {
    const BlogData = {
      Blog_Titale: data.Blog_Titale,
      Blog_Description: data.Blog_description,
      Blog_Date: data.Post_date,
      Img: blogImg,
    };
    fetch("https://foundingforusserver.vercel.app/blog/post", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(BlogData),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged) {
          reset();
          toast.success("Blog Post successful");
        }
      });
  };
  return (
    <section className=" py-20">
      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0">
          <div className="  mx-auto">
            <form
              className=" grid grid-cols-1 gap-5  border rounded shadow-lg p-10  lg:p-20  lg:w-1/2 mx-auto"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h2 className=" text-center text-xl text-gray-500  font-semibold">
                Blog Post
              </h2>
              <div>
                <input
                  {...register("Blog_Titale", { required: true })}
                  aria-invalid={errors.Blog_Titale ? "true" : "false"}
                  className=" border border-secondary mt-2 outline-none w-full py-4 px-3  rounded"
                  placeholder=" Blog Title"
                />
                {errors.Blog_Titale?.type === "required" && (
                  <p role="alert" className=" text-red-500">
                    Blog Title is required
                  </p>
                )}
              </div>

              <div>
                <input
                  type="text"
                  onFocus={(e) => (e.target.type = "date")}
                  {...register("Post_date", { required: true })}
                  aria-invalid={errors.Post_date ? "true" : "false"}
                  className=" border border-secondary mt-2 outline-none w-full py-4 px-3  rounded"
                  placeholder=" Date"
                />
                {errors.Post_date?.type === "required" && (
                  <p role="alert" className=" text-red-500">
                    Post Date is required
                  </p>
                )}
              </div>

              <div>
                <input
                  type="text"
                  onFocus={(e) => (e.target.type = "file")}
                  {...register(
                    "blog_img",
                    {
                      onChange: handleImg,
                    },
                    { required: true }
                  )}
                  aria-invalid={errors.blog_img ? "true" : "false"}
                  className=" border border-secondary mt-2 outline-none w-full py-4 px-3  rounded"
                  placeholder=" Upload Image"
                />
                {errors.blog_img?.type === "required" && (
                  <p role="alert" className=" text-red-500">
                    Blog Img is required
                  </p>
                )}
              </div>

              <div>
                <textarea
                  {...register("Blog_description", { required: true })}
                  aria-invalid={errors.Blog_description ? "true" : "false"}
                  className="w-full h-32 border border-secondary rounded py-4 px-3 outline-none mt-2"
                  placeholder="Blog Description..."
                ></textarea>
                {errors.Blog_description?.type === "required" && (
                  <p role="alert" className=" text-red-500">
                    Message is required
                  </p>
                )}
              </div>

              <input
                type="submit"
                value="POST"
                className=" bg-secondary w-full py-3 hover:bg-primary cursor-pointer text-white rounded "
              />
            </form>
          </div>
        </div>
      </div>
      <Toaster />
    </section>
  );
};

export default BlogPost;
