import React from "react";
import { useLoaderData } from "react-router-dom";

const LatestBlogDetails = () => {
  const { Blog_Date, Blog_Description, Blog_Titale, Img, _id } =
    useLoaderData();
  return (
    <section className=" py-20">
      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0">
          <div className=" border lg:w-1/2 mx-auto  rounded grid grid-cols-1 gap-5">
            <div className=" ">
              <img src={Img} />
            </div>
            <div className=" p-5 grid grid-cols-1 gap-3 text-gray-500">
              <p>{Blog_Date}</p>
              <h2 className=" text-2xl text-black font-semibold">
                {Blog_Titale}
              </h2>
              <p>{Blog_Description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LatestBlogDetails;
