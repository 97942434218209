import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaSpinner } from "react-icons/fa";
import Spinner from "../../Spinner/Spinner";
import DynamicTitle from "../../dynamic Titale/dynamicTitale";

const AllLoan = () => {
  const [allLoans, setallLoans] = useState([]);
  const [spinner, setspinner] = useState(true);

  useEffect(() => {}, [
    fetch("AllLoans.json").then((res) =>
      res.json().then((data) => {
        setallLoans(data);
        setspinner(false);
      })
    ),
  ]);

  return (
    <section className=" py-20 bg-primary">
      <DynamicTitle title={"Loan"}></DynamicTitle>

      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0">
          <div>
            <h2 className=" text-secondary font-bold text-center text-2xl ">
              All Loans Services
            </h2>
          </div>

          {spinner == true && <Spinner></Spinner>}

          <div className=" grid  grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-10 mt-10">
            {allLoans.map((loan) => (
              <div key={loan.id} className="  bg-[#080d41] p-10 rounded">
                <div className=" w-[70px] h-[70px] border flex justify-center items-center rounded-lg text-[28px]">
                  <img src={loan.img} alt="" className=" p-2" />
                </div>
                <div className=" mt-5">
                  <h2 className=" text-2xl font-semibold text-white">
                    {loan.Loan_name}
                  </h2>
                  <p className=" mt-5 text-info">{loan.about_loan}</p>
                </div>
                <div className="mt-10">
                  <Link
                    to="/loan_application"
                    className=" bg-secondary  px-5 py-3 text-white rounded"
                  >
                    Apply Now
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllLoan;
