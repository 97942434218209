import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../../../Spinner/Spinner";

const CreditRepair_recent_application = () => {
  const [spinner, setSpinner] = useState(true);

  const { data: recentApplications = [], refetch } = useQuery({
    queryKey: ["creditRepair_recent_application"],
    queryFn: async () => {
      const res = await fetch(
        "https://foundingforusserver.vercel.app/credit_repair/application/recent_application"
      );
      const data = res.json();
      setSpinner(false);
      return data;
    },
  });
  const markRead = (id) => {
    fetch(
      `https://foundingforusserver.vercel.app/credit_repair/recent/mark_read/${id}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (data._id) {
          const creditRepair = {
            firstName: data.firstName,
            LastName: data.LastName,
            Phone: data.Phone,
            Date_of_Birth: data.Date_of_Birth,
            Current_Adddress: data.Current_Adddress,
            City: data.City,
            State: data.State,
            Zipe_Code: data.Zipe_Code,
            Social_Security: data.Social_Security,
            How_long_this_address: data.How_long_this_address,
            Prior_Address: data.Prior_Address,
            Prior_City: data.Prior_City,
            Prior_state: data.Prior_state,
            Prior_zipe: data.Prior_zipe,
            have_you_used_card: data.have_you_used_card,
            have_you_ever_employed_USA_gov: data.have_you_ever_employed_USA_gov,
            have_you_recevied_government_benefits:
              data.have_you_recevied_government_benefits,
            are_you_veteran: data.are_you_veteran,
            Signature: data.Signature,
            Signature_date: data.Signature_date,
            Name: data.Name,
            step_two_Name: data.step_two_Name,
            step_two_Signature: data.step_two_Signature,
            step_two_Signature_Date: data.step_two_Signature_Date,
            step_two_Signature_Name: data.step_two_Signature_Name,
          };
          fetch(
            "https://foundingforusserver.vercel.app/credit_repair/application",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(creditRepair),
            }
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.acknowledged) {
                fetch(
                  `https://foundingforusserver.vercel.app/credit_repair/application/delete/${id}`,
                  {
                    method: "DELETE",
                  }
                )
                  .then((res) => res.json())
                  .then((data) => {
                    if (data.acknowledged) {
                      refetch();
                    }
                  });
              }
            });
        }
      });
  };

  return (
    <section className=" py-10">
      <div className=" grid grid-cols-1 gap-5">
        <div className=" grid grid-cols-1 gap-2 lg:flex justify-between items-center">
          <p className=" font-semibold capitalize">
            Dashboard /
            <span className=" px-2 py-1 ml-1 rounded-full bg-indigo-100 text-indigo-800">
              Recent Credit Repair Application
            </span>
          </p>
          <Link
            to="/Dashboard/credit-repair-application"
            className=" px-5 w-max inline-flex text-xs leading-8 font-semibold rounded-full bg-secondary text-white"
          >
            All Credit Repair Application
          </Link>
        </div>

        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                {spinner == true && <Spinner></Spinner>}

                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        SN
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        FIRST Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Last Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Phone
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        City
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        State
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Details
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {recentApplications.map((recentApplication, i) => (
                      <tr key={recentApplication._id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          {i + 1}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-500">
                          {recentApplication.firstName}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {recentApplication.LastName}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            {recentApplication.Phone}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {recentApplication.City}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full text-gray-500">
                            {recentApplication.State}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                          <Link
                            to={`/Dashboard/credit-repair/recent/details/${recentApplication._id}`}
                          >
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full  bg-indigo-200 text-indigo-800">
                              Details
                            </span>
                          </Link>
                        </td>
                        <td
                          onClick={() => markRead(recentApplication._id)}
                          className="px-6 py-4 whitespace-nowrap cursor-pointer"
                        >
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            Mark Read
                          </span>
                        </td>
                      </tr>
                    ))}

                    {/* Add more rows as needed */}
                  </tbody>
                </table>
                {recentApplications.length < 1 && (
                  <p className="px-6 py-4 whitespace-nowrap text-sm text-red-900 bg-red-100">
                    No Recent Application available{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreditRepair_recent_application;
