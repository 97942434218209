import React from "react";
import img from "../../Assat/banner-img.png";
import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <section className=" bg-[#edf9f9]">
      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0 py-10 grid grid-cols-1 gap-10 lg:flex justify-between items-center">
          <div className=" lg:w-1/2 grid grid-cols-1 gap-8">
            <h2 className=" text-3xl font-semibold text-center lg:text-left lg:text-6xl">
              Business loans with no hidden charges
            </h2>
            <p className=" text-[#353434] leading-7 text-center lg:text-left lg:leading-8">
              As entrepreneurs, we understand the challenges and uncertainties
              that come with starting and growing a business. That's why we're
              here to help. Our mission is to empower and inspire small business
              owners by providing them with the funding they need to bring their
              dreams to life.
            </p>
            <Link
              to="/loan_application"
              className=" bg-secondary  w-max mx-auto lg:ml-0 px-8 py-5 rounded text-white"
            >
              {" "}
              Apply Now
            </Link>
          </div>
          {/* img */}
          <div className=" lg:w-1/2">
            <img src={img} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
