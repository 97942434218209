import React from "react";
import c1 from "../../Assat/c1.jpg";
import c2 from "../../Assat/c2.jpg";
import c3 from "../../Assat/c3.jpg";
import { FaStar } from "react-icons/fa";

const Testimonials = () => {
  return (
    <section className=" py-20 bg-primary">
      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0">
          <div className=" grid grid-cols-1 gap-5 text-center text-secondary">
            <p className="">Testimonials</p>
            <p className=" text-4xl text-white font-bold">
              What Our Clients Are Saying
            </p>
          </div>
          {/* header end */}
          {/* body start */}
          <div className=" mt-20 grid grid-cols-1 lg:grid-cols-3 gap-10 ">
            <div className=" rounded-2xl bg-secondary relative px-5 py-14">
              <h2 className=" font-semibold text-xl text-center text-white">
                Alexis Leatherman
              </h2>
              <ul className=" flex gap-2 justify-center mt-2 text-[#ffd000]">
                <li>
                  <FaStar></FaStar>
                </li>
                <li>
                  <FaStar></FaStar>
                </li>
                <li>
                  <FaStar></FaStar>
                </li>
                <li>
                  <FaStar></FaStar>
                </li>
                <li>
                  <FaStar></FaStar>
                </li>
              </ul>

              <p className=" text-sm leading-6 text-justify mt-8 text-white">
                I had a great experience with Foundingforus. Their loan approval
                process was fast and hassle-free, and their customer service was
                exceptional. I appreciated the flexibility of their loan terms,
                and I felt confident that my personal information was safe and
                secure throughout the loan process. I highly recommend
                Foundingforus to anyone looking for a reliable loan service
                provider.
              </p>

              <div className=" w-[80px] h-[80px]   absolute top-[-35px] left-[41%] ">
                <img src={c1} className=" rounded-full " />
              </div>
            </div>
            <div className=" rounded-2xl bg-secondary relative px-5 py-14">
              <h2 className=" font-semibold text-xl text-center text-white">
                Kelly Brents
              </h2>
              <ul className=" flex gap-2 justify-center mt-2 text-[#ffd000]">
                <li>
                  <FaStar></FaStar>
                </li>
                <li>
                  <FaStar></FaStar>
                </li>
                <li>
                  <FaStar></FaStar>
                </li>
                <li>
                  <FaStar></FaStar>
                </li>
                <li>
                  <FaStar></FaStar>
                </li>
              </ul>

              <p className=" text-sm leading-6 text-justify mt-8 text-white">
                FUNDINGFORUS has been a huge help to my business. They are very
                knowledgeable in the global market and they know how to make the
                most of their connections. I was able to secure an SBLC for my
                LNG purchase and FUNDINGFORUS was able to get me an advanced
                loan for my cargo purchase. This increased my turnaround time
                which helped me grow my business exponentially”
              </p>

              <div className=" w-[80px] h-[80px]   absolute top-[-35px] left-[41%] ">
                <img src={c2} className=" rounded-full " />
              </div>
            </div>
            <div className=" rounded-2xl bg-secondary relative px-5 py-14">
              <h2 className=" font-semibold text-xl text-center text-white">
                Dawn Harrison
              </h2>
              <ul className=" flex gap-2 justify-center mt-2 text-[#ffd000]">
                <li>
                  <FaStar></FaStar>
                </li>
                <li>
                  <FaStar></FaStar>
                </li>
                <li>
                  <FaStar></FaStar>
                </li>
                <li>
                  <FaStar></FaStar>
                </li>
                <li>
                  <FaStar></FaStar>
                </li>
              </ul>

              <p className=" text-sm leading-6 text-justify mt-8 text-white">
                Foundingforus is an amazing loan service company that provides
                fast and secure loans with flexible terms. Their customer
                service is outstanding, and they go above and beyond to ensure
                that their clients receive the best possible loan services. I
                highly recommend Foundingforus to anyone in need of a loan.”
              </p>

              <div className=" w-[80px] h-[80px]   absolute top-[-35px] left-[41%] ">
                <img src={c3} className=" rounded-full " />
              </div>
            </div>
          </div>
          {/* body end */}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
