import React from "react";
import creaditRepair_img from "../../Assat/creadit-repair.png";
import { Link } from "react-router-dom";

const CreditRepair = () => {
  return (
    <section className=" py-20">
      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0">
          <div className=" grid grid-cols-1 lg:grid-cols-2 gap-10">
            <div>
              <img src={creaditRepair_img} alt="" />
            </div>
            <div>
              <div className=" grid grid-cols-1 gap-5">
                <h2 className=" text-2xl font-bold">
                  Rebuild, Repair & Improve Your Credit Score!
                </h2>
                <h2 className=" text-secondary text-2xl font-bold">
                  Credit Repair
                </h2>
                <p>
                  Are you tired of having a low credit score hold you back from
                  your financial goals? Do you want to take control of your
                  financial future and improve your credit standing?
                </p>
                <p>
                  Now is the time to take action and get started on your credit
                  sweep journey! With just 30 days of focused effort, you can
                  make significant progress towards improving your credit score
                  and achieving financial stability.
                </p>
                <p>
                  Don't let a low credit score dictate your financial future.
                  Take control now and start your credit sweep journey today!
                </p>
                <Link
                  to="/Credit_Repair_Application"
                  className="bg-secondary  w-max mx-auto lg:ml-0 px-8 py-5 rounded text-white"
                >
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreditRepair;
