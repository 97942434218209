import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../../../Spinner/Spinner";

const CreditRepair_Application = () => {
  const [spinner, setSpinner] = useState(true);

  const { data: creditRepairApplicaions = [], refetch } = useQuery({
    queryKey: ["creditRepairApplication"],
    queryFn: async () => {
      const res = await fetch(
        "https://foundingforusserver.vercel.app/creditRepairApplication"
      );
      const data = res.json();
      setSpinner(false);

      return data;
    },
  });

  const Credit_repair_application_Delete = (id) => {
    fetch(
      `https://foundingforusserver.vercel.app/creditRepairApplication/delete/${id}`,
      {
        method: "DELETE",
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.acknowledged) {
          refetch();
        }
      });
  };
  return (
    <section className=" py-10">
      <div className=" grid grid-cols-1 gap-5">
        <div className=" grid grid-cols-1 gap-2 lg:flex justify-between items-center">
          <p className=" font-semibold capitalize">
            Dashboard /
            <span className=" px-2 py-1 ml-1 rounded-full bg-indigo-100 text-indigo-800">
              Credit Repair Application
            </span>
          </p>
          <Link
            to="/Dashboard/credit-repair/recent-application"
            className=" px-5 w-max inline-flex text-xs leading-8 font-semibold rounded-full bg-secondary text-white"
          >
            All Recent Credit Repair Application
          </Link>
        </div>

        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                {spinner == true && <Spinner></Spinner>}

                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        SN
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        First Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Last Name
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Phone
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        City
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        State
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Details
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {creditRepairApplicaions.map(
                      (creditRepairApplicaion, i) => (
                        <tr key={creditRepairApplicaion._id}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm  text-gray-500">
                            {i + 1}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {creditRepairApplicaion.firstName}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {creditRepairApplicaion.LastName}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              {creditRepairApplicaion.Phone}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {creditRepairApplicaion.City}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              {creditRepairApplicaion.State}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                            <Link
                              to={`/Dashboard/credit-repair/details/${creditRepairApplicaion._id}`}
                            >
                              <span className="px-2 inline-flex text-xs leading-5  font-semibold rounded-full bg-indigo-100  text-indigo-800">
                                Details
                              </span>
                            </Link>
                          </td>
                          <td
                            onClick={() =>
                              Credit_repair_application_Delete(
                                creditRepairApplicaion._id
                              )
                            }
                            className="px-6 py-4 whitespace-nowrap cursor-pointer"
                          >
                            <span className="px-2 inline-flex text-xs leading-5  font-semibold rounded-full bg-red-100 text-red-800">
                              DELETE
                            </span>
                          </td>
                        </tr>
                      )
                    )}

                    {/* Add more rows as needed */}
                  </tbody>
                </table>
                {creditRepairApplicaions.length < 1 && (
                  <p className="px-6 py-4 bg-red-100 text-red-900 whitespace-nowrap text-sm ">
                    No Application available{" "}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreditRepair_Application;
