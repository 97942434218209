import React from "react";
import { useLoaderData } from "react-router-dom";

const CreditRepairDetails = () => {
  const {
    firstName,
    LastName,
    Phone,
    Date_of_Birth,
    Current_Adddress,
    City,
    State,
    Zipe_Code,
    Social_Security,
    How_long_this_address,
    Prior_Address,
    Prior_City,
    Prior_state,
    Prior_zipe,
    have_you_used_card,
    have_you_ever_employed_USA_gov,
    have_you_recevied_government_benefits,
    are_you_veteran,
    Signature,
    Signature_date,
    Name,
    step_two_Name,
    step_two_Signature,
    step_two_Signature_Date,
    step_two_Signature_Name,
  } = useLoaderData();
  return (
    <section className=" py-10">
      <div className=" max-w-[1300px] mx-auto">
        <div className=" lg:w-2/3 mx-2 lg:mx-auto">
          <table className=" min-w-full divide-y divide-gray-200 border shadow-lg">
            <tbody className="bg-white divide-y divide-gray-200 ">
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  <span className=" font-semibold text-green-800 bg-green-100 p-2 rounded ">
                    Credit Repair Application
                  </span>
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  <span className=" font-semibold ">Step - 1</span>
                </td>
              </tr>
              <tr>
                <td className="p-5   whitespace-nowrap text-md text-gray-500">
                  First Name
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {firstName}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Last Name
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {LastName}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Phone
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {Phone}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Date of Birth
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {Date_of_Birth}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Current Address
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {Current_Adddress}{" "}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  City
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {City}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  State
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {State}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Zipe Code
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {Zipe_Code}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Social Security Number
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {Social_Security}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  How Long At This Address{" "}
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {How_long_this_address} Year
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Prior Address
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {Prior_Address}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Prior City
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {Prior_City}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Prior State
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {Prior_state}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Prior Zipe Code
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {Prior_zipe}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Have You Used Card{" "}
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  <span className=" bg-green-100 text-green-900 px-2 font-semibold">
                    {have_you_used_card}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Have you ever been employed by the US Government?
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  <span className=" bg-green-100 text-green-900 px-2 font-semibold">
                    {have_you_ever_employed_USA_gov}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Have you ever received any government benefits of any kind?
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  <span className=" bg-green-100 text-green-900 px-2 font-semibold">
                    {have_you_recevied_government_benefits}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Are you a Veteran?
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  <span className=" bg-green-100 text-green-900 px-2 font-semibold">
                    {are_you_veteran}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Signature
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {Signature}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Signature Date
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {Signature_date}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Signature Owner Name
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {Name}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  <span className=" font-semibold ">Step - 2</span>
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Name
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {step_two_Name}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Signature
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {step_two_Signature}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Signature Date
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {step_two_Signature_Date}
                </td>
              </tr>
              <tr>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  Signature Owner Name
                </td>
                <td className="p-5  whitespace-nowrap text-md text-gray-500">
                  {step_two_Signature_Name}
                </td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default CreditRepairDetails;
