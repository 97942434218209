import React, { useEffect, useState } from "react";
import { FaUserTie, FaBlogger } from "react-icons/fa";
import { HiChatBubbleLeftEllipsis } from "react-icons/hi2";
import { GiPayMoney } from "react-icons/gi";
import { BsCreditCard2FrontFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const DashboardHome = () => {
  const [LoanData, setLoanData] = useState([]);
  useEffect(() => {
    fetch("https://foundingforusserver.vercel.app/Loan/Application")
      .then((res) => res.json())
      .then((data) => setLoanData(data));
  }, []);

  const [RecentLoanData, setRecentLoanData] = useState([]);
  useEffect(() => {
    fetch("https://foundingforusserver.vercel.app/loan/recent/application")
      .then((res) => res.json())
      .then((data) => setRecentLoanData(data));
  }, []);

  const [CreditRepair, SetCreditRepair] = useState([]);
  useEffect(() => {
    fetch("https://foundingforusserver.vercel.app/creditRepairApplication")
      .then((res) => res.json())
      .then((data) => SetCreditRepair(data));
  }, []);

  const [RecentCreditRepair, SetRecentCreditRepair] = useState([]);
  useEffect(() => {
    fetch(
      "https://foundingforusserver.vercel.app/credit_repair/application/recent_application"
    )
      .then((res) => res.json())
      .then((data) => SetRecentCreditRepair(data));
  }, []);

  const [RecentContactMessage, SetRecentContactMessage] = useState([]);
  useEffect(() => {
    fetch("https://foundingforusserver.vercel.app/recent/contact/message")
      .then((res) => res.json())
      .then((data) => SetRecentContactMessage(data));
  }, []);

  const [ContactMessage, SetContactMessage] = useState([]);
  useEffect(() => {
    fetch("https://foundingforusserver.vercel.app/contact/message")
      .then((res) => res.json())
      .then((data) => SetContactMessage(data));
  }, []);

  const [allBlogs, setallBlog] = useState([]);

  useEffect(() => {
    fetch("https://foundingforusserver.vercel.app/Blog/item")
      .then((res) => res.json())
      .then((data) => setallBlog(data));
  }, []);

  const [allUsers, setallUsers] = useState([]);
  useEffect(() => {
    fetch("https://foundingforusserver.vercel.app/UserInfo")
      .then((res) => res.json())
      .then((data) => setallUsers(data));
  }, []);

  const [Alladmin, setAlladmin] = useState([]);
  useEffect(() => {
    fetch("https://foundingforusserver.vercel.app/UserInfo/Admin")
      .then((res) => res.json())
      .then((data) => setAlladmin(data));
  }, []);

  return (
    <section className=" py-10">
      <div className=" grid lg:grid-cols-4 gap-5">
        {/* user start */}
        <div className=" border px-5 py-10 rounded bg-[#eaedf7]">
          <div className=" grid grid-cols-1 gap-3">
            <div className=" flex justify-between items-center">
              <p>Total Register User</p>
              <div className="  text-white bg-secondary p-2 rounded-full">
                <FaUserTie></FaUserTie>
              </div>
            </div>
            <p className=" text-4xl font-extrabold text-secondary">
              {allUsers.length}
            </p>
            <div className=" flex justify-between items-center">
              <p>Admin</p>
              <p>{Alladmin.length}</p>
            </div>
            <Link
              to="/Dashboard/user"
              className=" bg-secondary p-2 hover:bg-primary text-center text-white rounded"
            >
              View All
            </Link>
          </div>
        </div>
        {/*  user end */}
        {/* loan application start */}
        <div className=" border px-5 py-10 rounded bg-[#eaedf7]">
          <div className=" grid grid-cols-1 gap-3">
            <div className=" flex justify-between items-center">
              <p>Loan Application</p>
              <div className="  text-white bg-secondary p-2 rounded-full">
                <GiPayMoney></GiPayMoney>
              </div>
            </div>
            <p className=" text-4xl font-extrabold text-secondary">
              {LoanData.length}
            </p>
            <div className=" flex justify-between items-center">
              <p>Recent Application</p>
              <p>{RecentLoanData.length}</p>
            </div>
            <Link
              to="/Dashboard/loan/recent_application"
              className=" bg-secondary p-2 text-center hover:bg-primary text-white rounded"
            >
              View All
            </Link>
          </div>
        </div>
        {/*  loan application  end */}

        {/* Credit Repair application start */}
        <div className=" border px-5 py-10 rounded bg-[#eaedf7]">
          <div className=" grid grid-cols-1 gap-3">
            <div className=" flex justify-between items-center">
              <p>Credit Repair A..</p>
              <div className="  text-white bg-secondary p-2 rounded-full">
                <BsCreditCard2FrontFill></BsCreditCard2FrontFill>
              </div>
            </div>
            <p className=" text-4xl font-extrabold text-secondary">
              {CreditRepair.length}
            </p>
            <div className=" flex justify-between items-center">
              <p>Recent Application</p>
              <p>{RecentCreditRepair.length}</p>
            </div>
            <Link
              to="/Dashboard/credit-repair/recent-application"
              className=" hover:bg-primary bg-secondary p-2 text-center text-white rounded"
            >
              View All
            </Link>
          </div>
        </div>
        {/*  Credit Repair application  end */}

        {/* Contact Message start */}
        <div className=" border px-5 py-10 rounded bg-[#eaedf7]">
          <div className=" grid grid-cols-1 gap-3">
            <div className=" flex justify-between items-center">
              <p>Contact Message</p>
              <div className="  text-white bg-secondary p-2 rounded-full">
                <HiChatBubbleLeftEllipsis></HiChatBubbleLeftEllipsis>
              </div>
            </div>
            <p className=" text-4xl font-extrabold text-secondary">
              {ContactMessage.length}
            </p>
            <div className=" flex justify-between items-center">
              <p>Recent Message</p>
              <p>{RecentContactMessage.length}</p>
            </div>
            <Link
              to="/Dashboard/Recent/Contact_Message"
              className=" bg-secondary hover:bg-primary p-2 dis text-center text-white rounded"
            >
              View All
            </Link>
          </div>
        </div>
        {/*  Blog post  end */}
        <div className=" border px-5 py-10 rounded bg-[#eaedf7]">
          <div className=" grid grid-cols-1 gap-3">
            <div className=" flex justify-between items-center">
              <p>Blog </p>
              <div className="  text-white bg-secondary p-2 rounded-full">
                <FaBlogger></FaBlogger>
              </div>
            </div>
            <p className=" text-4xl font-extrabold text-secondary">
              {allBlogs.length}
            </p>
            <Link
              to="/Dashboard/Manage/Blog"
              className=" underline text-secondary cursor-pointer "
            >
              Manage Blog
            </Link>
            <Link
              to="/Dashboard/Blog/Post"
              className=" bg-secondary p-2 hover:bg-primary dis text-center text-white rounded"
            >
              Blog Post
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardHome;
