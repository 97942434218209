import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaCheckCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import DynamicTitle from "../../dynamic Titale/dynamicTitale";

const Credit_Repair_Application = () => {
  const [credit_Repair_data, set_credit_Repair_data] = useState({});
  const [How_Long_This_address, set_How_Long_This_address] = useState(0);
  const [Show_Step_One, Set_Show_Step_One] = useState(true);
  const [Show_Step_two, Set_Show_Step_two] = useState(false);
  const [Show_Complited_Step, Set_Show_Complited_Step] = useState(false);
  const [step_one_Done, Set_step_one_Done] = useState(false);
  const [step_two_Done, Set_step_two_Done] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const step_one_Submit = (data) => {
    Set_Show_Step_One(false);
    Set_Show_Step_two(true);
    Set_step_one_Done(true);
  };
  const step_two_Submit = (data) => {
    set_credit_Repair_data(data);
    Set_Show_Complited_Step(true);
    Set_Show_Step_two(false);
    Set_step_two_Done(true);

    fetch(
      "https://foundingforusserver.vercel.app/credit_repair/recent/application",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          toast.success("Thanks for your Application ");
        }
      });
  };

  // zipe code length limite start
  const [zipe_code, set_zipe_code] = useState("");
  const [Prior_zipe_code, set_Prior_zipe_code] = useState("");

  const zipcodeChange = (e) => {
    const zipeCode = e.target.value;
    if (zipeCode.length <= 5) {
      set_zipe_code(zipeCode);
    }
  };

  const [socialSecurityNumber, SetsocialSecurityNumber] = useState("");
  const Social_Security_Change = (e) => {
    const Social_security_number = e.target.value;
    if (Social_security_number.length <= 9) {
      SetsocialSecurityNumber(Social_security_number);
    }
  };

  const prior_zipeCode_change = (e) => {
    const prior_zipe_code = e.target.value;
    if (prior_zipe_code.length <= 5) {
      set_Prior_zipe_code(prior_zipe_code);
    }
  };
  // zipe code length limite start

  // usa all state data load start
  const [Allstates, setAllstates] = useState([]);
  useEffect(() => {
    fetch("USA_All_state.json")
      .then((res) => res.json())
      .then((data) => setAllstates(data));
  }, []);
  // usa all state data load end

  return (
    <section className=" py-20">
      <DynamicTitle title={"Credit Repair A."}></DynamicTitle>
      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0">
          <div className=" lg:w-2/3 grid grid-cols-1 gap-20 border  mx-auto p-10 shadow-md">
            {/* credit repair header start */}
            <div className=" grid grid-cols-1 gap-10">
              <div>
                <p className=" text-2xl text-secondary font-semibold">
                  Credit Repair Application
                </p>
                <p className=" text-sm text-[#666666]">
                  Welcome To FundingForUS
                </p>
              </div>

              <div className=" grid grid-cols-2 gap-1">
                <p
                  className={`bg-primary p-3 ${
                    step_one_Done === true && "border-secondary border-b-8 "
                  } text-xl text-white text-center`}
                >
                  Step - 1
                </p>
                <p
                  className={`bg-primary p-3 ${
                    step_two_Done === true && " border-secondary border-b-8"
                  }  text-xl text-white text-center`}
                >
                  Step - 2
                </p>
              </div>
            </div>
            {/* credit repair header end */}

            {/* ============================ step - 1 start ========================= */}
            {Show_Step_One === true && (
              <div className=" ">
                <form onSubmit={handleSubmit(step_one_Submit)}>
                  <div className="grid grid-cols-1 gap-5">
                    {/* 1st  and last start */}
                    <div className=" grid grid-cols-2 gap-5">
                      <div>
                        <div className=" border p-4 rounded bg-[#f9f9f9] ">
                          <input
                            className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                            placeholder=" First Name"
                            {...register("firstName", { required: true })}
                            aria-invalid={errors.firstName ? "true" : "false"}
                          />
                        </div>
                        {errors.firstName?.type === "required" && (
                          <p role="alert" className=" text-red-400">
                            First name is required
                          </p>
                        )}
                      </div>
                      <div>
                        <div className=" border p-4 rounded bg-[#f9f9f9] ">
                          <input
                            className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                            placeholder=" Last Name"
                            {...register("LastName", { required: true })}
                            aria-invalid={errors.LastName ? "true" : "false"}
                          />
                        </div>
                        {errors.LastName?.type === "required" && (
                          <p role="alert" className=" text-red-400">
                            Last name is required
                          </p>
                        )}
                      </div>
                    </div>
                    {/* 1st  and last end */}

                    {/* phone  and DOB start*/}
                    <div className=" grid grid-cols-2 gap-5">
                      <div>
                        <div className=" border p-4 rounded bg-[#f9f9f9] ">
                          <input
                            type="number"
                            className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                            placeholder=" Phone ( +1 857 000 0000 )"
                            {...register("Phone", { required: true })}
                            aria-invalid={errors.Phone ? "true" : "false"}
                          />
                        </div>
                        {errors.Phone?.type === "required" && (
                          <p role="alert" className=" text-red-400">
                            Phone name is required
                          </p>
                        )}
                      </div>
                      <div>
                        <div className=" border p-4 rounded bg-[#f9f9f9] ">
                          <input
                            type="text"
                            placeholder="Date of Birth"
                            onFocus={(e) => (e.target.type = "date")}
                            className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                            {...register("Date_of_Birth", { required: true })}
                            aria-invalid={
                              errors.Date_of_Birth ? "true" : "false"
                            }
                          />
                        </div>
                        {errors.Date_of_Birth?.type === "required" && (
                          <p role="alert" className=" text-red-400">
                            Birth Day is required
                          </p>
                        )}
                      </div>
                    </div>
                    {/* phone  and DOB end*/}

                    {/* current address and city start */}
                    <div className=" grid grid-cols-2 gap-5">
                      <div>
                        <div className=" border p-4 rounded bg-[#f9f9f9] ">
                          <input
                            className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                            placeholder=" Current Adddress"
                            {...register("Current_Adddress", {
                              required: true,
                            })}
                            aria-invalid={
                              errors.Current_Adddress ? "true" : "false"
                            }
                          />
                        </div>
                        {errors.Current_Adddress?.type === "required" && (
                          <p role="alert" className=" text-red-400">
                            Current Adddress is required
                          </p>
                        )}
                      </div>
                      <div>
                        <div className=" border p-4 rounded bg-[#f9f9f9] ">
                          <input
                            className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                            placeholder=" City"
                            {...register("City", { required: true })}
                            aria-invalid={errors.City ? "true" : "false"}
                          />
                        </div>
                        {errors.City?.type === "required" && (
                          <p role="alert" className=" text-red-400">
                            City is required
                          </p>
                        )}
                      </div>
                    </div>
                    {/* current address and city end */}

                    {/* state and zipe code start */}
                    <div className=" grid grid-cols-2 gap-5">
                      <div>
                        <div className=" ">
                          <select
                            className="border p-4 bg-[#f9f9f9] w-full text-[#666666]  rounded outline-none"
                            {...register("State", { required: true })}
                            aria-invalid={errors.State ? "true" : "false"}
                          >
                            <option value="" className=" ">
                              State
                            </option>
                            {Allstates.map((state, i) => (
                              <option value={state.name} className=" " key={i}>
                                {state.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {errors.State?.type === "required" && (
                          <p role="alert" className=" text-red-400">
                            State is required
                          </p>
                        )}
                      </div>
                      <div>
                        <div className=" border p-4 rounded bg-[#f9f9f9] ">
                          <input
                            type="number"
                            value={zipe_code}
                            className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                            placeholder=" Zipe Code"
                            {...register(
                              "Zipe_Code",
                              {
                                onChange: zipcodeChange,
                              },
                              { required: true }
                            )}
                            aria-invalid={errors.Zipe_Code ? "true" : "false"}
                          />
                        </div>
                        {errors.Zipe_Code?.type === "required" && (
                          <p role="alert" className=" text-red-400">
                            Zipe Code is required
                          </p>
                        )}
                      </div>
                    </div>
                    {/* state and zipe code end */}

                    {/* social security and how long in this address start */}
                    <div className=" grid grid-cols-2 gap-5">
                      <div>
                        <div className=" border p-4 rounded bg-[#f9f9f9] ">
                          <input
                            type="number"
                            value={socialSecurityNumber}
                            className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                            placeholder=" Social Security Number"
                            {...register(
                              "Social_Security",
                              {
                                onChange: Social_Security_Change,
                              },
                              { required: true }
                            )}
                            aria-invalid={
                              errors.Social_Security ? "true" : "false"
                            }
                          />
                        </div>
                        {errors.Social_Security?.type === "required" && (
                          <p role="alert" className=" text-red-400">
                            Social Security Number is required
                          </p>
                        )}
                      </div>
                      <div>
                        <div className=" border p-4 rounded bg-[#f9f9f9] ">
                          <input
                            type="number"
                            className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                            placeholder=" How long at this address?"
                            {...register(
                              "How_long_this_address",
                              {
                                onChange: (e) => {
                                  set_How_Long_This_address(e.target.value);
                                },
                              },
                              {
                                required: true,
                              }
                            )}
                            aria-invalid={
                              errors.How_long_this_address ? "true" : "false"
                            }
                          />
                        </div>
                        {errors.How_long_this_address?.type === "required" && (
                          <p role="alert" className=" text-red-400">
                            How long at this address?
                          </p>
                        )}
                      </div>
                    </div>
                    {/* social security and how long in this address end */}

                    {/* prior address information start */}
                    {/* ==================================== */}
                    {parseFloat(How_Long_This_address) < 4 && (
                      <div className="grid grid-cols-1 gap-5">
                        {/* prior address and city */}
                        <div className=" grid grid-cols-2 gap-5">
                          <div>
                            <div className=" border p-4 rounded bg-[#f9f9f9] ">
                              <input
                                className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                                placeholder="Prior Address"
                                {...register("Prior_Address", {
                                  required: true,
                                })}
                                aria-invalid={
                                  errors.Prior_Address ? "true" : "false"
                                }
                              />
                            </div>
                            {errors.Prior_Address?.type === "required" && (
                              <p role="alert" className=" text-red-400">
                                Prior Address is required
                              </p>
                            )}
                          </div>
                          <div>
                            <div className=" border p-4 rounded bg-[#f9f9f9] ">
                              <input
                                className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                                placeholder="Prior City"
                                {...register("Prior_City", { required: true })}
                                aria-invalid={
                                  errors.Prior_City ? "true" : "false"
                                }
                              />
                            </div>
                            {errors.Prior_City?.type === "required" && (
                              <p role="alert" className=" text-red-400">
                                Prior City is required
                              </p>
                            )}
                          </div>
                        </div>
                        {/* prior state and zipe code */}
                        <div className=" grid grid-cols-2 gap-5">
                          <div>
                            <div className=" ">
                              <select
                                className="border p-4 bg-[#f9f9f9] w-full text-[#666666]  rounded outline-none"
                                {...register("Prior_state", { required: true })}
                                aria-invalid={
                                  errors.Prior_state ? "true" : "false"
                                }
                              >
                                <option value="" className=" ">
                                  Prior State
                                </option>
                                {Allstates.map((state, i) => (
                                  <option
                                    value={state.name}
                                    className=" "
                                    key={i}
                                  >
                                    {state.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {errors.Prior_state?.type === "required" && (
                              <p role="alert" className=" text-red-400">
                                Prior State is required
                              </p>
                            )}
                          </div>

                          <div>
                            <div className=" border p-4 rounded bg-[#f9f9f9] ">
                              <input
                                type="number"
                                value={Prior_zipe_code}
                                className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                                placeholder="Prior Zipe Code"
                                {...register(
                                  "Prior_zipe",
                                  { onChange: prior_zipeCode_change },
                                  { required: true }
                                )}
                                aria-invalid={
                                  errors.Prior_zipe ? "true" : "false"
                                }
                              />
                            </div>
                            {errors.Prior_zipe?.type === "required" && (
                              <p role="alert" className=" text-red-400">
                                Prior Zipe Code is required
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {/* prior address information end */}
                    {/* ==================================== */}

                    {/* have_you_used_card */}
                    <div>
                      <div className=" grid grid-cols-1 gap-3">
                        <p className=" text-[#666666] text-justify">
                          Have you ever used a credit, debit card or shared your
                          personal information with any of the following
                          companies at any of their locations? – Macy’s, Sears,
                          Home Depot, Verizon, Kmart, Wendy’s, Delta Airlines,
                          Michaels, Best Buy, Landry’s, Saks Fifth Avenue, JP
                          Morgan Chase, Lord & Taylor, Facebook Account, Panera,
                          Ebay, Forever 21, Orbitz, Sonic, Chili’s Restaurants,
                          WholeFoods, Uber, Arby’s, Target Stores, Walmart,
                          Marriott, Quest Diagnostics, Experian or Equifax?
                        </p>
                        <select
                          className="border p-4 bg-[#f9f9f9] w-full text-[#666666]  rounded outline-none"
                          {...register("have_you_used_card", {
                            required: true,
                          })}
                          aria-invalid={
                            errors.have_you_used_card ? "true" : "false"
                          }
                        >
                          <option value="" className=" ">
                            Select Option
                          </option>
                          <option value="YES" className=" ">
                            YES
                          </option>
                          <option value="NO" className=" ">
                            NO
                          </option>
                        </select>
                      </div>
                      {errors.have_you_used_card?.type === "required" && (
                        <p role="alert" className=" text-red-400">
                          Select Option
                        </p>
                      )}
                    </div>
                    {/* Have you ever been employed by the US Government? */}
                    <div>
                      <div className=" grid grid-cols-1 gap-3">
                        <p className=" text-[#666666] text-justify">
                          Have you ever been employed by the US Government?
                        </p>
                        <select
                          className="border p-4 bg-[#f9f9f9] w-full text-[#666666]  rounded outline-none"
                          {...register("have_you_ever_employed_USA_gov", {
                            required: true,
                          })}
                          aria-invalid={
                            errors.have_you_ever_employed_USA_gov
                              ? "true"
                              : "false"
                          }
                        >
                          <option value="" className=" ">
                            Select Option
                          </option>
                          <option value="YES" className=" ">
                            YES
                          </option>
                          <option value="NO" className=" ">
                            NO
                          </option>
                        </select>
                      </div>
                      {errors.have_you_ever_employed_USA_gov?.type ===
                        "required" && (
                        <p role="alert" className=" text-red-400">
                          Select Option
                        </p>
                      )}
                    </div>
                    {/* Have you ever received any government benefits of anykind?  */}
                    <div>
                      <div className=" grid grid-cols-1 gap-3">
                        <p className=" text-[#666666] text-justify">
                          Have you ever received any government benefits of any
                          kind?
                        </p>
                        <select
                          className="border p-4 bg-[#f9f9f9] w-full text-[#666666]  rounded outline-none"
                          {...register(
                            "have_you_recevied_government_benefits",
                            {
                              required: true,
                            }
                          )}
                          aria-invalid={
                            errors.have_you_recevied_government_benefits
                              ? "true"
                              : "false"
                          }
                        >
                          <option value="" className=" ">
                            Select Option
                          </option>
                          <option value="YES" className=" ">
                            YES
                          </option>
                          <option value="NO" className=" ">
                            NO
                          </option>
                        </select>
                      </div>
                      {errors.have_you_recevied_government_benefits?.type ===
                        "required" && (
                        <p role="alert" className=" text-red-400">
                          Select Option
                        </p>
                      )}
                    </div>
                    {/*Are you a Veteran?  */}
                    <div>
                      <div className=" grid grid-cols-1 gap-3">
                        <p className=" text-[#666666] text-justify">
                          Are you a Veteran?
                        </p>
                        <select
                          className="border p-4 bg-[#f9f9f9] w-full text-[#666666]  rounded outline-none"
                          {...register("are_you_veteran", {
                            required: true,
                          })}
                          aria-invalid={
                            errors.are_you_veteran ? "true" : "false"
                          }
                        >
                          <option value="" className=" ">
                            Select Option
                          </option>
                          <option value="YES" className=" ">
                            YES
                          </option>
                          <option value="NO" className=" ">
                            NO
                          </option>
                        </select>
                      </div>
                      {errors.are_you_veteran?.type === "required" && (
                        <p role="alert" className=" text-red-400">
                          Select Option
                        </p>
                      )}
                    </div>

                    {/* ====================================================== */}
                    {/* Fill Out The Service Agreement */}
                    <div className="grid grid-cols-1 gap-5 text-sm text-[#666666] text-justify">
                      <h2 className=" font-bold text-secondary">
                        Fill Out The Service Agreement
                      </h2>
                      <div className=" grid grid-cols-1 gap-3">
                        <p>
                          The agreement between the Guaranteed Funding, a
                          non-profit organization, (“GF”, “We”, “Our”, “Ours”,
                          or “Us”)
                        </p>
                        <p>is a legally binding agreement (the “Agreement”).</p>
                        <p>
                          GF contracts with third parties to provide document
                          preparation services to assist its clients who may be
                          victims of identity theft and desire to protect their
                          credit file from identity theft and possibly fraud by
                          facilitating removal of items customers claim are
                          inaccurate, outdated, and possibly fraudulent
                          information contained on their credit reports. For our
                          efforts to be effective, you must be truthful and
                          diligent in giving complete and accurate information
                          to us. GF reserves the right to cancel this Agreement
                          if we believe that you provided or may provide false
                          or fraudulent information to us, your creditors or the
                          credit bureaus.
                        </p>
                        <p>
                          Please note that we do not provide legal advice. We
                          recommend that you consult your attorney and/or
                          thoroughly read the Fair Credit Reporting Act or the
                          Bill of Rights before you seek advice from us. GF
                          accepts no liability, nor responsibility for any
                          damage or loss caused by your use or misuse of the
                          information provided in connection with our service.
                        </p>
                        <p>
                          Disclaimer: We are not a credit repair agency. Our
                          third-party providers prepare documents to remove
                          erroneous items as described from consumers that
                          believe that they may have fallen victim to identity
                          theft. By signing this Agreement, you acknowledge that
                          there are inconsistencies, errors and items on your
                          consumer credit reports that are unknown to you and
                          permission was never requested or given to have your
                          personal information provided to the public, sold for
                          profit or not protected in what you feel is a proper
                          way.
                        </p>
                      </div>

                      <div className="grid grid-cols-1 gap-3">
                        <h2 className=" font-bold">
                          GF and/or its third-party contractors provide:
                        </h2>
                        <ul
                          style={{ listStyleType: "inherit" }}
                          className=" grid grid-cols-1 gap-3"
                        >
                          <li>
                            An evaluation of your claim that your current credit
                            reports contain possible identity theft items that
                            are inaccurate, erroneous, false, possibly
                            fraudulent, or obsolete information being reported
                            by credit reporting agencies such as Experian,
                            Equifax and Transunion
                          </li>
                          <li>
                            Preparation of all necessary documents in
                            facilitating removal of inaccurate, erroneous,
                            false, mis-reported, fraudulent or obsolete
                            information in your credit reports that may have
                            derived from Identity Theft that can impact you
                          </li>
                          <li>
                            Placing a temporary fraud alert on your credit file
                            to prevent any and all unauthorized use of your
                            identity to obtain credit fraudulently
                          </li>
                          <li>
                            Services are considered completed when potential
                            fraud or identity theft related items, have been
                            deleted from your credit report
                          </li>
                          <li>
                            Support primarily via email (
                            Support@fundingforus.net).
                          </li>
                        </ul>
                      </div>

                      <div className="grid grid-cols-1 gap-3">
                        <h2 className=" font-bold">Your Responsibility:</h2>
                        <ul
                          style={{ listStyleType: "inherit" }}
                          className=" grid grid-cols-1 gap-3"
                        >
                          <li>
                            Not apply for credit during the term of this
                            Agreement and do not increase revolving credit
                            utilization. Ideally, you’ll reduce your revolving
                            utilization, if applicable.
                          </li>
                          <li>
                            The normal document processing fee is waived but you
                            are responsible for maintaining the credit
                            monitoring service as stated in Step 1
                          </li>
                          <li>Fully complete and sign this Agreement.</li>
                          <li>
                            Promptly email the following documents to
                            Support@GuaranteedFunding.org in one email:
                          </li>
                          <li>
                            Copy of current, valid state Drivers’ License or
                            Government issued Photo ID
                          </li>
                          <li>
                            Copy of a recent utility bill or bank statement
                            showing your name and current residence address
                            (must be less than 60 days old).
                          </li>
                        </ul>
                      </div>

                      <div className="grid grid-cols-1 gap-3">
                        <h2 className=" font-bold">Agreement Terms:</h2>
                        <p>
                          Term – The term of this Agreement is three (3) months
                          from its execution and all services must be performed
                          during that period. The Agreement may be extended for
                          an additional three (3) month period by an amendment
                          to this Agreement.
                        </p>
                        <p>
                          Guarantee – Although we cannot guarantee by law a
                          certain outcome, we prepare documents conforming to
                          Federal Law, certain state Privacy Laws, and the Fair
                          Credit Reporting Act. We will prepare documents to
                          assist you to accurately report to the credit bureaus
                          and place a fraud alert on your file to assist in
                          protecting you against future data breaches and
                          attempts to use your identity in a fraudulent manner.
                        </p>
                        <p>
                          Governing Law –This agreement shall be governed by the
                          laws of the State of Texas and Client agrees that any
                          legal or equitable action for claims, debts, or
                          obligations arising out of, or to enforce the terms
                          of, this Agreement may be brought by either party only
                          in Wood County, TX and that such court shall have
                          personal jurisdiction over the parties and the venue
                          of action shall be appropriate in such court.
                        </p>
                        <p>
                          Entire Agreement – THE TERMS OF THIS AGREEMENT SHALL
                          CONTROL OVER ANY CONFLICTING TERMS IN ANY REFERENCED
                          AGREEMENT OR DOCUMENT. THIS AGREEMENT SETS FORTH AND
                          CONSTITUTES THE ENTIRE AGREEMENT AND UNDERSTANDING OF
                          THE PARTIES WITH RESPECT TO THE SUBJECT MATTER HEREOF.
                          THIS AGREEMENT SUPERSEDES ANY AND ALL PRIOR
                          AGREEMENTS, NEGOTIATIONS, CORRESPONDENCE,
                          UNDERTAKINGS, PROMISES, COVENANTS, ARRANGEMENTS
                          COMMUNICATIONS, REPRESENTATIONS, AND WARRANTIES,
                          WHETER ORAL OR WRITTEN, OF ANY PARTY TO THIS
                          AGREEMENT.
                        </p>
                        <p>
                          Binding Arbitration – Any dispute under this Agreement
                          with the exception of client’s failure to pay the
                          processing fee shall be resolved by binding
                          arbitration to be held in-person in Wood County,
                          Texas. Each party shall pay its own costs associated
                          with the arbitration and the parties shall equally pay
                          the cost of securing the arbitrator and the venue.
                          This section shall survive the termination or
                          cancellation of this Agreement.
                        </p>
                        <p>
                          Class Action Waiver – Any arbitration or court trial
                          of any Claim will take place on an individual basis
                          without resort to any form of class or representative
                          action. Non-Profit Credit Counseling Services – You
                          have the right to hire a non-profit credit counseling
                          service.
                        </p>
                        <p>
                          Customer Responsibilities and Communications – You
                          agree to assist us in answering certain security
                          questions regarding your identity and credit history
                          as may be necessary to obtain your credit reports.
                        </p>
                        <p>
                          Staffing– GF may assign clerical staff, or others to
                          perform work on your case. You agree services in
                          connection with our representation of you may be
                          performed by any associate of GF and/or its contracted
                          parties.
                        </p>
                        <p>
                          By signing this Agreement, you agree to its terms and
                          conditions, agree to be truthful, and you understand
                          that no promises have been made by GF to you outside
                          of this Agreement.
                        </p>
                      </div>

                      <div className=" grid grid-cols-1 gap-3">
                        <div>
                          <div className=" border p-4 rounded bg-[#f9f9f9] ">
                            <input
                              className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                              placeholder="  Name"
                              {...register("Name", { required: true })}
                              aria-invalid={errors.Name ? "true" : "false"}
                            />
                          </div>
                          {errors.Name?.type === "required" && (
                            <p role="alert" className=" text-red-400">
                              Name is required
                            </p>
                          )}
                        </div>
                        <div>
                          <div className=" border p-4 rounded bg-[#f9f9f9] ">
                            <input
                              className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                              placeholder="  Signature - Type Your Name"
                              {...register("Signature", { required: true })}
                              aria-invalid={errors.Signature ? "true" : "false"}
                            />
                          </div>
                          {errors.Signature?.type === "required" && (
                            <p role="alert" className=" text-red-400">
                              Signature is required
                            </p>
                          )}
                        </div>
                        <div>
                          <div className=" border p-4 rounded mt-2 bg-[#f9f9f9] ">
                            <input
                              type="text"
                              placeholder="Signature Date "
                              onFocus={(e) => (e.target.type = "date")}
                              className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                              {...register("Signature_date", {
                                required: true,
                              })}
                              aria-invalid={
                                errors.Signature_date ? "true" : "false"
                              }
                            />
                          </div>
                          {errors.Signature_date?.type === "required" && (
                            <p role="alert" className=" text-red-400">
                              Signature Date is required
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div>
                      <input
                        type="submit"
                        value="Sumbit & Continue"
                        className=" bg-secondary w-full p-4 rounded text-white text-lg cursor-pointer"
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}

            {/* ============================ step - 1 end ========================= */}
            {Show_Step_two === true && (
              <div>
                <form onSubmit={handleSubmit(step_two_Submit)}>
                  <div className=" grid grid-cols-1 gap-3">
                    <p>Limited Power of Attorney Disclosure:</p>
                    <p>
                      GF and/or its third party contracted providers need
                      permission from you, to communicate with credit bureaus,
                      creditors, data furnishers and others, in your name and on
                      your behalf, including writing, signing and transmitting
                      letters and electronic documents in your name. This is a
                      Limited Power of Attorney, granting permission to GF
                      and/or its third party contractors to do this. It
                      authorizes and directs GF and/or its third party
                      contractors to act as your disclosed and undisclosed agent
                      when performing the Services, you have retained GF to
                      provide. You may cancel your authorization and this
                      Limited Power of Attorney at any time by sending GF
                      Resource Revocation of Limited Power of Attorney stating
                      that you retract your authorization. Without this written
                      authorization and Limited Power of Attorney, GF is unable
                      to represent you, therefore canceling this authorization
                      will close your case. Please print a copy of the Agreement
                      and this Limited Power of Attorney for Your records.
                    </p>

                    <div>
                      <div className=" inline">
                        Be it known that by submitting this form, I
                      </div>
                      <div className=" inline">
                        <input
                          className=" inline text-center outline-none border-secondary border-b-[1px] placeholder-[#666666] bg-[#f9f9f9]"
                          placeholder="  Name"
                          {...register("step_two_Name", { required: true })}
                          aria-invalid={errors.step_two_Name ? "true" : "false"}
                        />
                        {errors.step_two_Name?.type === "required" && (
                          <p role="alert" className=" text-red-400 inline">
                            Name is required
                          </p>
                        )}
                      </div>
                      <div className=" inline">
                        {" "}
                        hereby grant a Limited Power of Attorney to GF and/or
                        its third-party contractors and all persons in their
                        employ, as my agent, to have the necessary power and
                        authority to undertake and perform the following in my
                        behalf
                      </div>
                    </div>

                    <div>
                      <ul
                        style={{ listStyleType: "inherit" }}
                        className=" grid grid-cols-1 gap-5"
                      >
                        <li>
                          I hereby give permission to GF and/or its third-party
                          contractors to sign all documents written on my
                          behalf, as my duly appointed proxy, for the purpose of
                          disputing inaccurate, erroneous, fraudulent,
                          derogatory and obsolete credit information held on my
                          credit report by consumer credit reporting agencies.
                        </li>
                        <li>
                          I appoint GF and/or its third-party contractors as my
                          agent to act in my behalf, as set forth in the
                          following matters only; signing of correspondences
                          addressed to credit bureaus, creditors and data
                          furnishers, obtaining credit information over the
                          telephone, fax, and/or through written correspondence
                          from credit bureaus, creditors, data furnishers and/or
                          collection agencies.
                        </li>
                        <li>
                          I have given Limited Power of Attorney to act on my
                          behalf with government agencies in an effort to
                          prepare and submit the needed documentation and
                          granted by law to have immediate action taken by the
                          credit bureaus regarding derogatory items on my credit
                          report and possible fraud or identity theft.
                        </li>
                        <li>
                          I understand that I have the right to revoke or
                          terminate this Limited Power of Attorney at any time
                          with a written Revocation of Limited Power of Attorney
                          to GF.
                        </li>
                        <li>
                          According to the Consumer Credit File Rights, Under
                          State and Federal Law, I have been made aware of the
                          fact that I could attempt to challenge items specified
                          above myself.
                        </li>
                        <li>
                          This “Limited Power of Attorney” is given to GF in
                          compliance with Section 611 of the Federal Fair Credit
                          Reporting Act (FCRA).
                        </li>
                      </ul>
                    </div>

                    <div>
                      <div className=" border p-4 rounded bg-[#f9f9f9] ">
                        <input
                          className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                          placeholder="  Name"
                          {...register("step_two_Signature_Name", {
                            required: true,
                          })}
                          aria-invalid={
                            errors.step_two_Signature_Name ? "true" : "false"
                          }
                        />
                      </div>
                      {errors.step_two_Signature_Name?.type === "required" && (
                        <p role="alert" className=" text-red-400">
                          Name is required
                        </p>
                      )}
                    </div>

                    <div>
                      <div className=" border p-4 rounded bg-[#f9f9f9] ">
                        <input
                          className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                          placeholder="  Signature  - Type Your Name"
                          {...register("step_two_Signature", {
                            required: true,
                          })}
                          aria-invalid={
                            errors.step_two_Signature ? "true" : "false"
                          }
                        />
                      </div>
                      {errors.step_two_Signature?.type === "required" && (
                        <p role="alert" className=" text-red-400">
                          Signature is required
                        </p>
                      )}
                    </div>

                    <div>
                      <div className=" border p-4 rounded bg-[#f9f9f9] ">
                        <input
                          type="text"
                          onFocus={(e) => (e.target.type = "date")}
                          className=" w-full outline-none placeholder-[#666666] bg-[#f9f9f9]"
                          placeholder="  Signature  Date"
                          {...register("step_two_Signature_Date", {
                            required: true,
                          })}
                          aria-invalid={
                            errors.step_two_Signature_Date ? "true" : "false"
                          }
                        />
                      </div>
                      {errors.step_two_Signature_Date?.type === "required" && (
                        <p role="alert" className=" text-red-400">
                          Signature Date is required
                        </p>
                      )}
                    </div>

                    <div>
                      <input
                        type="submit"
                        value="Complited"
                        className=" bg-secondary w-full p-4 rounded text-white text-lg cursor-pointer"
                      />
                    </div>
                  </div>
                </form>
              </div>
            )}
            {/* ==========================================================================*/}

            {/* ========================== complited step start ========================== */}
            {Show_Complited_Step === true && (
              <div>
                <div className=" flex justify-center">
                  <div className=" grid grid-cols-1 gap-4">
                    <FaCheckCircle className=" text-secondary text-3xl mx-auto"></FaCheckCircle>
                    <h2 className=" text-center">
                      Thanks For Your Application
                    </h2>
                    <Link
                      to="/"
                      className=" bg-secondary text-center p-3 rounded text-white"
                    >
                      Go Back To Home
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Toaster />
    </section>
  );
};

export default Credit_Repair_Application;
