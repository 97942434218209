import { createBrowserRouter } from "react-router-dom";
import Main from "../Layout/Main/Main";
import Home from "../Page/Home/Home";
import About from "../Page/About/About";
import Contact from "../Page/Contact/Contact";
import AllLoan from "../Page/AllLoan/AllLoan";
import Blog from "../Page/Blog/Blog";
import Faq from "../Page/Faq/Faq";
import LoanApplication from "../Page/LoanApplication/LoanApplication";
import Register from "../Page/Register/Register";
import Login from "../Page/Login/Login";
import Credit_Repair_Application from "../Page/Credit_Repair_Application/Credit_Repair_Application";
import Dashboard from "../Layout/Dashboard/Dashboard";
import DashboardHome from "../Layout/Dashboard/DashboardHome/DashboardHome";
import Dashboard_User_admin from "../Layout/Dashboard/Dashboard_User/Dashboard_User_admin";
import Dashboard_User from "../Layout/Dashboard/Dashboard_User/Dashboard_User";
import Dashboard_loan_recent_application from "../Layout/Dashboard/Dashboard_Application/Dashboard_loan_recent_application";
import Dashboard_loan from "../Layout/Dashboard/Dashboard_Application/Dashboard_loan";
import CreditRepair_recent_application from "../Layout/Dashboard/Dashboard_Application/Credit Repair/CreditRepair_recent_application";
import CreditRepair_Application from "../Layout/Dashboard/Dashboard_Application/Credit Repair/CreditRepair_Application";
import RecentLoanDetails from "../Layout/Dashboard/RecentLoanDetails/RecentLoanDetails";
import LoanDetails from "../Layout/Dashboard/LoanDetails/LoanDetails";
import CreditRepairRecentDetails from "../Layout/Dashboard/CreditRepairRecentDetails/CreditRepairRecentDetails";
import CreditRepairDetails from "../Layout/Dashboard/CreditRepairDetails/CreditRepairDetails";
import Recent_Contact_Message from "../Layout/Dashboard/Contact_Message/Recent_Contact_Message";
import Contact_Message from "../Layout/Dashboard/Contact_Message/Contact_Message";
import RecentContactDetails from "../Layout/Dashboard/Contact_Message/RecentContactDetails";
import ContactDetails from "../Layout/Dashboard/Contact_Message/ContactDetails";
import ManageBlog from "../Layout/Dashboard/ManageBlog/ManageBlog";
import BlogPost from "../Layout/Dashboard/BlogPost/BlogPost";
import BlogDetails from "../Page/Blog/BlogDetails";
import PasswordReset from "../Page/PasswordReset/PasswordReset";
import PrivateRoute from "./PrivateRoute";
import LatestBlogDetails from "../Page/LatestBlogDetails/LatestBlogDetails";

const route = createBrowserRouter([
  {
    path: "/",
    element: <Main></Main>,
    children: [
      {
        path: "/",
        element: <Home></Home>,
      },
      {
        path: "/about",
        element: <About></About>,
      },
      {
        path: "/contact",
        element: <Contact></Contact>,
      },
      {
        path: "/loan",
        element: <AllLoan></AllLoan>,
      },
      {
        path: "/blog",
        element: <Blog></Blog>,
      },
      {
        path: "/faq",
        element: <Faq></Faq>,
      },
      {
        path: "/loan_application",
        element: <LoanApplication></LoanApplication>,
      },
      {
        path: "/Register",
        element: <Register></Register>,
      },
      {
        path: "/Login",
        element: <Login></Login>,
      },
      {
        path: "/Credit_Repair_Application",
        element: <Credit_Repair_Application></Credit_Repair_Application>,
      },
      {
        path: "/blog/details/:id",
        element: <BlogDetails></BlogDetails>,
        loader: ({ params }) => {
          return fetch(
            `https://foundingforusserver.vercel.app/blog/details/${params.id}`
          );
        },
      },
      {
        path: "/Latest/blog/details/:id",
        element: <LatestBlogDetails></LatestBlogDetails>,
        loader: ({ params }) => {
          return fetch(
            `https://foundingforusserver.vercel.app/Latest/blog/details/${params.id}`
          );
        },
      },
      {
        path: "/Password_Reset",
        element: <PasswordReset></PasswordReset>,
      },
    ],
  },
  {
    path: "/Dashboard",
    element: (
      <PrivateRoute>
        <Dashboard></Dashboard>
      </PrivateRoute>
    ),
    children: [
      {
        path: "/Dashboard",
        element: <DashboardHome></DashboardHome>,
      },
      {
        path: "/Dashboard/user/admin",
        element: <Dashboard_User_admin></Dashboard_User_admin>,
      },
      {
        path: "/Dashboard/user",
        element: <Dashboard_User></Dashboard_User>,
      },
      {
        path: "/Dashboard/loan/recent_application",
        element: (
          <Dashboard_loan_recent_application></Dashboard_loan_recent_application>
        ),
      },
      {
        path: "/Dashboard/loan_application",
        element: <Dashboard_loan></Dashboard_loan>,
      },
      {
        path: "/Dashboard/credit-repair/recent-application",
        element: (
          <CreditRepair_recent_application></CreditRepair_recent_application>
        ),
      },
      {
        path: "/Dashboard/credit-repair-application",
        element: <CreditRepair_Application></CreditRepair_Application>,
      },
      {
        path: "/Dashboard/loan/recent/details/:id",
        element: <RecentLoanDetails></RecentLoanDetails>,
        loader: ({ params }) => {
          return fetch(
            `https://foundingforusserver.vercel.app/loan/recent/details/${params.id}`
          );
        },
      },
      {
        path: "/Dashboard/loan/details/:id",
        element: <LoanDetails></LoanDetails>,
        loader: ({ params }) => {
          return fetch(
            `https://foundingforusserver.vercel.app/Dashboard/loan/application/details/${params.id}`
          );
        },
      },
      {
        path: "/Dashboard/credit-repair/recent/details/:id",
        element: <CreditRepairRecentDetails></CreditRepairRecentDetails>,
        loader: ({ params }) => {
          return fetch(
            `https://foundingforusserver.vercel.app/credit-repair/recent/details/${params.id}`
          );
        },
      },
      {
        path: "/Dashboard/credit-repair/details/:id",
        element: <CreditRepairDetails></CreditRepairDetails>,
        loader: ({ params }) => {
          return fetch(
            `https://foundingforusserver.vercel.app/credit-repair/details/${params.id}`
          );
        },
      },
      {
        path: "/Dashboard/Recent/Contact_Message",
        element: <Recent_Contact_Message></Recent_Contact_Message>,
      },
      {
        path: "/Dashboard/Contact_Message",
        element: <Contact_Message></Contact_Message>,
      },

      {
        path: "/Dashboard/recent/contact/details/:id",
        element: <RecentContactDetails></RecentContactDetails>,
        loader: ({ params }) => {
          return fetch(
            `https://foundingforusserver.vercel.app/Recent/Contact/Message/details/${params.id}`
          );
        },
      },

      {
        path: "/Dashboard/contact/message/details/:id",
        element: <ContactDetails></ContactDetails>,
        loader: ({ params }) => {
          return fetch(
            `https://foundingforusserver.vercel.app/Contact/Message/details/${params.id}`
          );
        },
      },
      {
        path: "/Dashboard/Manage/Blog",
        element: <ManageBlog></ManageBlog>,
      },
      {
        path: "/Dashboard/Blog/Post",
        element: <BlogPost></BlogPost>,
      },
    ],
  },
]);
export default route;
