import React, { useEffect, useState } from "react";
import { HiChevronDown } from "react-icons/hi2";
import Spinner from "../../Spinner/Spinner";
import DynamicTitle from "../../dynamic Titale/dynamicTitale";

const Faq = () => {
  const [faqs, setfaqs] = useState([]);
  const [spinner, setspinner] = useState(true);

  useEffect(() => {
    fetch("faq.json")
      .then((res) => res.json())
      .then((data) => {
        setfaqs(data);
        setspinner(false);
      });
  }, []);
  return (
    <section className=" py-20">
      <DynamicTitle title={"FAQ"}></DynamicTitle>
      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0 grid grid-cols-1 gap-5">
          {spinner == true && (
            <div>
              <Spinner></Spinner>
            </div>
          )}

          <div className=" grid grid-cols-1 gap-5">
            {faqs.map((faq) => (
              <div key={faq.id} className="collapse ">
                <input type="checkbox" className="peer" />
                <div className="collapse-title flex gap-2  border rounded items-center justify-between">
                  <h2 className=" font-bold">{faq.question}</h2>
                  <HiChevronDown></HiChevronDown>
                </div>
                <div className="collapse-content ">
                  <p>{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
