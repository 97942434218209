import React from "react";
import { useLoaderData } from "react-router-dom";

const LoanDetails = () => {
  const {
    firstName,
    Last_Name,
    email,
    number,
    Address,
    City,
    State,
    Postal_Code,
    Country,
    Month,
    Year,
    Day,
    Business_Name,
    Business_start_date,
    Entity_Type,
    Industry,
    Average_Monthly_Revenue,
  } = useLoaderData();

  return (
    <section className=" py-10">
      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0">
          <div className=" lg:w-2/3 lg:mx-auto ">
            <table className="min-w-full divide-y divide-gray-200 border shadow-lg ">
              <tbody className="bg-white divide-y divide-gray-200">
                <tr>
                  <td className="p-5  whitespace-nowrap text-md text-gray-500">
                    <span className=" font-semibold text-green-800 bg-green-100 p-2 rounded ">
                      Loan Application Details{" "}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    First Name
                  </td>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    {firstName}
                  </td>
                </tr>
                <tr>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    Last Name
                  </td>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    {Last_Name}
                  </td>
                </tr>
                <tr>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    Email
                  </td>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    {email}
                  </td>
                </tr>
                <tr>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    Phone
                  </td>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    {number}
                  </td>
                </tr>
                <tr>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    Address
                  </td>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    {Address}
                  </td>
                </tr>
                <tr>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    City
                  </td>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    {City}
                  </td>
                </tr>
                <tr>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    State
                  </td>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    {State}
                  </td>
                </tr>
                <tr>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    Postal Code
                  </td>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    {Postal_Code}
                  </td>
                </tr>
                <tr>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    Countrie
                  </td>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    {Country}
                  </td>
                </tr>
                <tr>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    Date Of Birth
                  </td>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    <span>
                      <span> {Day}</span>
                      <span> {Month}</span>
                      <span> {Year}</span>
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    Business Name
                  </td>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    {Business_Name}
                  </td>
                </tr>
                <tr>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    Business Start Date
                  </td>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    {Business_start_date}
                  </td>
                </tr>
                <tr>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    Entity Type{" "}
                  </td>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    {Entity_Type}
                  </td>
                </tr>
                <tr>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    Industry
                  </td>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    {Industry}
                  </td>
                </tr>
                <tr>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    Average Monthly Revenue
                  </td>
                  <td className="p-5   whitespace-nowrap text-md text-gray-500">
                    ${Average_Monthly_Revenue}
                  </td>
                </tr>

                {/* Add more rows as needed */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoanDetails;
