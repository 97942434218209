import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Context/AuthProvider";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Link } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [CheckAdminData, setCheckAdminData] = useState({});

  useEffect(() => {
    fetch(
      `https://foundingforusserver.vercel.app/user/check/admin/${user?.email}`
    )
      .then((res) => res.json())
      .then((data) => setCheckAdminData(data));
  }, [user?.email]);

  return (
    <>
      {CheckAdminData.Role == "admin" ? (
        <> {children} </>
      ) : (
        <section>
          <Header></Header>
          <div className=" py-20">
            <div className=" max-w-[1300px] mx-auto">
              <div className=" mx-2 lg:mx-0">
                <div className="">
                  <h2 className=" text-2xl font-bold text-center">
                    You Have Not Access This Page
                  </h2>
                  <div className=" text-center mt-5">
                    <Link
                      to="/"
                      className=" btn bg-secondary border-none hover:bg-primary "
                    >
                      Go Back To Home{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </section>
      )}
    </>
  );
};

export default PrivateRoute;
