import React, { useContext, useEffect, useState } from "react";
import { FaBars, FaXing } from "react-icons/fa";
import { Link } from "react-router-dom";
import { AuthContext } from "../Context/AuthProvider";
import logo from "../Assat/logo/logo.png";
//
const BottomHeader = () => {
  const [showManu, setShowManu] = useState(false);
  const { user, userSingOut } = useContext(AuthContext);
  const [CheckAdminData, setCheckAdminData] = useState();

  const logout = () => {
    userSingOut()
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    fetch(
      `https://foundingforusserver.vercel.app/user/check/admin/${user?.email}`
    )
      .then((res) => res.json())
      .then((data) => setCheckAdminData(data));
  }, [user?.email]);

  const clickTOshow = () => {
    setShowManu(true);
  };
  const clickTOhide = () => {
    setShowManu(false);
  };

  const manuItem = (
    <>
      <li>
        <Link className=" hover:text-secondary" to="/">
          home
        </Link>
      </li>
      <li>
        <Link className=" hover:text-secondary" to="/about">
          about
        </Link>
      </li>
      <li>
        <Link className=" hover:text-secondary" to="/loan">
          loan
        </Link>
      </li>
      <li>
        <Link className=" hover:text-secondary" to="/contact">
          contact us
        </Link>
      </li>
      <li>
        <Link className=" hover:text-secondary" to="/blog">
          Blog
        </Link>
      </li>
      <li>
        <Link className=" hover:text-secondary" to="/faq">
          {" "}
          Faq
        </Link>
      </li>

      {CheckAdminData?.Role === "admin" && (
        <li>
          <Link className=" hover:text-secondary" to="/Dashboard">
            Dashboard
          </Link>
        </li>
      )}

      <li>
        {user?.uid ? (
          <Link onClick={logout} className=" ">
            Logout
          </Link>
        ) : (
          <Link
            className=" bg-secondary px-5 py-4 rounded text-white hover:bg-primary hover:text-secondary "
            to="/Register"
          >
            Register Now
          </Link>
        )}
      </li>
    </>
  );
  return (
    <div className=" mx-2 lg:mx-0   ">
      <div className=" max-w-[1300px] mx-auto relative">
        <div className=" flex justify-between items-center ">
          <div className=" text-2xl font-bold w-[200px]  ">
            <img className="  w-full" src={logo} />
          </div>

          <div className=" hidden lg:block">
            <ul className=" flex capitalize gap-6 items-center text-primary text-md font-medium cursor-pointer">
              {manuItem}
            </ul>
          </div>

          <div className="lg:hidden">
            <div className=" bg-secondary w-[40px] cursor-pointer h-[40px] rounded-full flex justify-center text-white items-center text-2xl">
              {showManu === false ? (
                <FaBars onClick={clickTOshow}></FaBars>
              ) : (
                <FaXing onClick={clickTOhide}></FaXing>
              )}
            </div>
          </div>
        </div>
        <div className="    absolute top-[55px] w-full">
          <div
            className={` ${
              showManu === true
                ? " scale-y-100 duration-150"
                : " scale-y-0 duration-150 "
            } lg:hidden mt-2 bg-primary text-secondary py-10 px-12 rounded`}
          >
            <ul className=" grid grid-cols-1 gap-5 cursor-pointer ">
              {manuItem}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomHeader;
