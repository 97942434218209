import React, { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { FaCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";
import DynamicTitle from "../../dynamic Titale/dynamicTitale";

const Blog = () => {
  const [allBlogs, setallBlog] = useState([]);
  const [spinner, setspinner] = useState(true);

  useEffect(() => {
    fetch("https://foundingforusserver.vercel.app/Blog/item")
      .then((res) => res.json())
      .then((data) => {
        setallBlog(data);
        setspinner(false);
      });
  }, []);

  return (
    <section className=" py-20">
      <DynamicTitle title={"Blog"}></DynamicTitle>
      <div className=" max-w-[1300px] mx-auto">
        <div className=" mx-2 lg:mx-0 grid grid-cols-1 gap-5">
          {spinner == true && <Spinner></Spinner>}
          <div className=" grid grid-cols-1 lg:grid-cols-3 gap-5  ">
            {allBlogs.map((blog) => (
              <div key={blog._id} className=" border text-white ">
                <div className=" h-[270px] ">
                  <img src={blog.Img} className=" h-[100%] w-[100%]" />
                </div>
                <div className=" p-5 grid grid-cols-1 gap-4">
                  <div className=" text-black flex items-center gap-4">
                    <FaCalendarAlt></FaCalendarAlt>
                    <p>{blog.Blog_Date}</p>
                  </div>
                  <h2 className="text-black text-2xl font-bold">
                    {blog.Blog_Titale}
                  </h2>
                  <div className=" text-secondary flex gap-3 items-center font-semibold text-[18px]">
                    <Link to={`/blog/details/${blog._id}`}>Read More</Link>
                    <BsArrowRight></BsArrowRight>
                  </div>
                </div>
              </div>
            ))}

            {allBlogs.length < 1 && (
              <p className="p-2 rounded bg-red-100 text-red-900 w-max whitespace-nowrap text-sm ">
                No Blog available{" "}
              </p>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blog;
