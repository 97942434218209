import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinner from "../../Spinner/Spinner";

const OurService = () => {
  const [PopluarLoans, setPopluarLoan] = useState([]);
  const [spinner, setSpinner] = useState(true);
  useEffect(() => {}, [
    fetch("PopluarLoan.json")
      .then((res) => res.json())
      .then((data) => {
        setPopluarLoan(data);
        setSpinner(false);
      }),
  ]);
  return (
    <section className=" bg-primary py-20">
      <div className=" max-w-[1300px] mx-auto ">
        <div className=" mx-2 lg:mx-0 grid grid-cols-1 gap-14">
          <div className=" grid grid-cols-1 gap-5 text-center text-secondary">
            <p className="">What we're offering</p>
            <p className=" text-4xl text-white font-bold">Loans Services</p>
          </div>
          {spinner == true && <Spinner></Spinner>}
          <div className=" grid lg:grid-cols-2 gap-8 lg:gap-10 ">
            {PopluarLoans.map((PopluarLoan) => (
              <div key={PopluarLoan.id} className="  bg-[#080d41] p-10 rounded">
                <div className=" w-[70px] h-[70px] border flex justify-center items-center rounded-lg text-[28px]">
                  <img src={PopluarLoan.img} alt="" className=" p-2" />
                </div>
                <div className=" mt-5">
                  <h2 className=" text-2xl font-semibold text-white">
                    {PopluarLoan.Loan_name}
                  </h2>
                  <p className=" mt-5 text-info">{PopluarLoan.about_loan}</p>
                </div>

                <div className=" mt-10">
                  <Link
                    to="/loan_application"
                    className=" bg-secondary mt-5 px-5 py-3 text-white rounded"
                  >
                    Apply Now
                  </Link>
                </div>
              </div>
            ))}
          </div>

          <div className=" text-center">
            <Link
              to="/loan"
              className="bg-secondary px-8 py-5 text-white rounded"
            >
              View All Services
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurService;
