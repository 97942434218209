import React from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { HiEnvelope } from "react-icons/hi2";
import { MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
const TopHeader = () => {
  return (
    <div className=" bg-primary hidden lg:block mx-2 lg:mx-0">
      <div className=" text-secondary py-3 flex justify-between max-w-[1300px] mx-auto">
        <div>
          <ul className=" flex gap-8 text-sm">
            <li className=" flex items-center gap-2">
              <FaPhoneAlt></FaPhoneAlt>
              <span className=" text-info">+1 248-539-5404</span>
            </li>
            <li className=" flex items-center gap-2">
              <HiEnvelope></HiEnvelope>
              <span className=" text-info">info@FundingForUs.net</span>
            </li>
            <li className=" flex items-center gap-2">
              <MdLocationOn></MdLocationOn>
              <span className=" text-info">
                {" "}
                510 South Pacific Mineola, TX 75773{" "}
              </span>
            </li>
          </ul>
        </div>
        <div>
          <ul className=" flex text-sm text-info gap-5">
            <li className=" cursor-pointer">
              <Link to="/contact"> Support</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
