import React from "react";
import { Link } from "react-router-dom";

const DashboardManu = () => {
  const DashboardManuItem = (
    <>
      <li className="hover:text-secondary">
        <Link to="/"> Home</Link>
      </li>
      <li className="hover:text-secondary">
        <Link to="/Dashboard"> Dashboard Home</Link>
      </li>
    </>
  );
  return (
    <div className=" hidden lg:flex justify-between items-center py-5">
      <div>
        <h2 className=" text-2xl font-extrabold text-secondary">
          FundingForUS
        </h2>
      </div>

      <div>
        <ul className=" flex gap-10 cursor-pointer font-semibold ">
          {DashboardManuItem}
        </ul>
      </div>
    </div>
  );
};

export default DashboardManu;
